import { createAdditionalTenant, getOrganizations } from '@/api/workspaces.ts'
import { Button } from '@/components/ui/button'
import { CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input.tsx'
import { Label } from '@/components/ui/label.tsx'
import { Switch } from '@/components/ui/switch.tsx'
import { Toaster } from '@/components/ui/toaster.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { LockIcon, LucideIcon, MoreHorizontal } from 'lucide-react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { DefaultDataTable } from '../DefaultDataTable'
import { WorkspaceEditModal } from './WorkspaceEditModal'

interface NewWorkspaceForm {
    name: string
    accessControlGroup: boolean
}

const Workspaces: React.FC = () => {
    const allOrganizationsQuery = useQuery({
        queryKey: [QueryKeys.ORGANIZATIONS],
        queryFn: getOrganizations,
    })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [row, setRow] = useState<{ id: string; name: string; accessControlGroupId: string }>()

    const { t } = useTranslation()

    const columns: ColumnDef<any>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'createdAt',
            header: t('created_date'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.createdAt).toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">
                                    {t('workspaces.actions.open_menu')}
                                </span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={() => {
                                    setRow(row.original)
                                    setIsModalOpen(true)
                                }}
                            >
                                <div className="flex flex-row gap-1">
                                    <LockIcon className="h-4 w-4" />
                                    {t('workspaces.actions.access_control')}
                                </div>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    return (
        <>
            {allOrganizationsQuery.data && (
                <div className="min-h-[80vh] mr-3 bg-background">
                    <CardHeader>
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col gap-2 grow">
                                <CardTitle>{t('workspaces.workspaces')}</CardTitle>
                                <CardDescription>
                                    {t('workspaces.workspaces_description')}
                                </CardDescription>
                            </div>
                            <CreateNewTenant
                                variant="default"
                                onSuccess={() =>
                                    toast({
                                        title: t('success_title'),
                                        description: t('workspaces.workspace_create_success'),
                                    })
                                }
                            />
                        </div>
                    </CardHeader>
                    <CardContent>
                        <DefaultDataTable
                            onRowClick={(row: any) => {
                                console.log(row)
                            }}
                            data={allOrganizationsQuery.data.flatMap((org) => org.tenants)}
                            columns={columns}
                        />
                    </CardContent>
                </div>
            )}
            <WorkspaceEditModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                tenant={{
                    id: row?.id ?? '',
                    name: row?.name ?? '',
                    accessControlGroupId: row?.accessControlGroupId ?? '',
                }}
            />
            <Toaster />
        </>
    )
}

export const CreateNewTenant = ({
    variant,
    onSuccess,
    size,
    Icon,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon'
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const queryClient = useQueryClient()
    const [openCreate, setOpenCreate] = useState(false)
    const appForm = useForm<NewWorkspaceForm>({
        defaultValues: {
            name: '',
            accessControlGroup: false,
        },
    })
    const { t } = useTranslation()

    const handleCreateWorkspace = async (data: NewWorkspaceForm) => {
        try {
            //@ts-ignore
            await createAdditionalTenant(data.name, data.accessControlGroup)
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANIZATIONS] })
            setOpenCreate(false)
            onSuccess && onSuccess()
        } catch (error) {
            console.log(error)
            toast({
                title: t('error_title'),
                description: 'Something went wrong on the workspace creation',
                variant: 'destructive',
            })
        }
    }
    return (
        <div>
            <Button type="button" size={size} onClick={() => setOpenCreate(true)} variant={variant}>
                {Icon ? (
                    <Icon className="text-muted-foreground h-5 w-5" />
                ) : (
                    t('workspaces.workspace_create')
                )}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate}>
                <form onSubmit={appForm.handleSubmit(handleCreateWorkspace)}>
                    <DialogContent className="max-w-modal-sm max-h-modal">
                        <DialogHeader>
                            <DialogTitle>{t('workspaces.workspace_create')}</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4 py-4 w-full">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <div className="flex flex-col gap-2 min-w-72 w-full">
                                    <Label htmlFor="name" className="text-left">
                                        {t('name')}
                                    </Label>
                                    <Input
                                        id="name"
                                        type="text"
                                        className="col-span-3"
                                        {...appForm.register('name', { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-72">
                                <Label htmlFor="accessControlGroup" className="mr-2">
                                    {t('workspaces.access_control')}
                                </Label>
                                <Controller
                                    name="accessControlGroup"
                                    control={appForm.control}
                                    render={({ field }) => (
                                        <Switch
                                            id="userSpecific"
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                type="submit"
                                onClick={appForm.handleSubmit(handleCreateWorkspace)}
                            >
                                {t('create')}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}

export default Workspaces
