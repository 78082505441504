import { Tool, getToolById, updateTool } from '@/api/tools'
import { Parameter } from '@/components/tools/Parameter'
import { ParametersCodeBlock } from '@/components/tools/ParametersCodeBlock'
import { UseCase } from '@/components/tools/UseCase'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn } from '@/lib/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Info, PlusIcon, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/tools/$toolId')({
    component: () => {
        return <EditTool />
    },
})

type Header = {
    key: string
    value: string
}

function EditTool() {
    const navigate = Route.useNavigate()
    const params = Route.useParams()

    const getToolQuery = useQuery({
        queryKey: [QueryKeys.TOOLS, params.toolId],
        queryFn: () => getToolById(params.toolId),
    })

    const { control, register, handleSubmit, getValues, setValue, formState } = useForm({
        defaultValues: {
            name: '',
            purpose: '',
            httpMethod: 'POST',
            url: '',
        },
    })
    const [parameters, setParameters] = useState<Parameter[]>([
        { id: uuidv4(), name: '', type: '', description: '', validOptions: [] },
    ])
    const [useCases, setUseCases] = useState<UseCase[]>([
        {
            id: uuidv4(),
            description: '',
            examples: [
                { id: uuidv4(), toolParameterId: parameters[0].id, value: '', enabled: false },
            ],
        },
    ])
    const [headers, setHeaders] = useState<Header[]>([{ key: '', value: '' }])
    const [codeBlockValue, setCodeBlockValue] = useState('')
    const [codeBlockLanguage, setCodeBlockLanguage] = useState('')
    const [toolHttpMethod, setToolHttpMethod] = useState('')
    const [toolUrl, setToolUrl] = useState('')

    const getBody = () => {
        const json: { [key: string]: string | number } = {}

        useCases[0].examples.forEach((param) => {
            const paramDetails = parameters.find((p) => p.id === param.toolParameterId)
            if (paramDetails?.name && param.enabled)
                json[paramDetails.name] =
                    paramDetails.type === 'string' ? param.value : parseInt(param.value)
        })

        return json
    }

    const buildQueryString = () => {
        const queryParams = new URLSearchParams()
        useCases[0].examples.forEach((param) => {
            const paramDetails = parameters.find((p) => p.id === param.toolParameterId)
            if (paramDetails?.name && param.enabled)
                queryParams.append(paramDetails.name, param.value)
        })
        return queryParams.toString() ? `?${queryParams.toString()}` : ''
    }

    const handleAddParameter = () => {
        const newParameterId = uuidv4()
        const newParameters = [
            ...parameters,
            {
                id: newParameterId,
                toolDefinitionId: params.toolId,
                name: '',
                description: '',
                type: '',
                value: '',
                validOptions: [],
            },
        ]
        setParameters(newParameters)
        setUseCases((prev) => {
            return prev.map((useCase) => {
                return {
                    ...useCase,
                    examples: [
                        ...useCase.examples,
                        {
                            id: uuidv4(),
                            toolParameterId: newParameterId,
                            value: '',
                            enabled: false,
                        },
                    ],
                }
            })
        })
    }

    const handleAddUseCase = () => {
        setUseCases((prev) => [
            ...prev,
            {
                id: uuidv4(),
                userSays: '',
                description: '',
                examples: parameters.map((param) => {
                    return {
                        id: uuidv4(),
                        toolParameterId: param.id,
                        value: '',
                        enabled: false,
                    }
                }),
            },
        ])
    }

    const handleUpdateTool = async (data: any) => {
        const formattedHeaders = headers.filter((header) => {
            return header.key !== '' && header.value !== ''
        })

        const updatedTool = {
            id: params.toolId,
            name: data.name,
            description: data.purpose,
            methodType: data.httpMethod,
            purpose: data.purpose,
            apiEndpoint: data.url,
            headers: formattedHeaders,
            parameters: parameters,
            useCases: useCases,
            projectId: params.projectId,
        } as Tool
        await updateToolMutation.mutateAsync(updatedTool)
    }

    const updateToolMutation = useMutation({
        mutationFn: (data: Tool) => updateTool(data),
        onSuccess: () => {
            navigate({ to: '/$projectId/tools', params: { projectId: params.projectId } })
        },
        onError: (error) => {
            console.log(error)
        },
    })

    useEffect(() => {
        if (toolHttpMethod === 'POST') {
            setCodeBlockLanguage('js')
            setCodeBlockValue(JSON.stringify(getBody(), null, 2))
        } else if (toolHttpMethod === 'GET') {
            setCodeBlockLanguage('url')
            setCodeBlockValue(toolUrl + buildQueryString())
        }
    }, [parameters, useCases, toolHttpMethod, toolUrl])

    useEffect(() => {
        if (getToolQuery.data) {
            setParameters(getToolQuery.data.parameters)
            setUseCases(getToolQuery.data.useCases)
            setHeaders(getToolQuery.data.headers)
            setValue('name', getToolQuery.data.name)
            setValue('purpose', getToolQuery.data.purpose)
            setValue('url', getToolQuery.data.apiEndpoint)
            setValue('httpMethod', getToolQuery.data.methodType)
            console.log(getToolQuery.data)
            setToolHttpMethod(getToolQuery.data.methodType)
            setToolUrl(getToolQuery.data.apiEndpoint)
        }
    }, [getToolQuery.data])

    const generatePromptPreview = (
        name: string,
        purpose: string,
        parameters: Parameter[],
        useCases: UseCase[]
    ) => {
        const parameterDescriptions = parameters.map((p) => (
            <li key={p.id}>{`'${p.name}': ${p.description} (${p.type}).`}</li>
        ))

        const useCaseExamples = useCases.map((useCase, index) => {
            const exampleJson = JSON.stringify(
                Object.fromEntries(
                    useCase.examples
                        .filter((e) => e.enabled)
                        .map((e) => {
                            const param = parameters.find((p) => p.id === e.toolParameterId)
                            return [param?.name, e.value]
                        })
                ),
                null,
                2
            )

            return (
                <li key={index}>
                    If the user says "{useCase.description}" you would return:
                    <pre>
                        <code className="language-json">{exampleJson}</code>
                    </pre>
                </li>
            )
        })

        return (
            <>
                <div className="font-azeret">Function JSON format:</div>
                <ul className="font-azeret" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        {name} function:
                        <ul
                            className="font-azeret"
                            style={{ listStyleType: 'circle', paddingLeft: '20px' }}
                        >
                            <li>Function name: '{name}'</li>
                            <li>Purpose: {purpose}</li>
                        </ul>
                    </li>
                    <li>
                        Parameters:
                        <ul
                            className="font-azeret"
                            style={{ listStyleType: 'circle', paddingLeft: '20px' }}
                        >
                            {parameterDescriptions.map((param, index) => (
                                <li key={index}>{param}</li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        Examples of how to return the {name} function JSON:
                        <ul
                            className="font-azeret"
                            style={{ listStyleType: 'circle', paddingLeft: '20px' }}
                        >
                            {useCaseExamples.map((example, index) => (
                                <li key={index}>{example}</li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </>
        )
    }

    const displayPromptPreview = () => {
        return (
            getValues('name') !== '' &&
            getValues('purpose') !== '' &&
            parameters.length > 0 &&
            parameters[0].name !== '' &&
            useCases.length > 0 &&
            useCases[0].description !== '' &&
            useCases[0].examples.some((e) => e.enabled) &&
            useCases[0].examples.some((e) => e.value !== '')
        )
    }

    return (
        <div className="p-2">
            <form onSubmit={handleSubmit(handleUpdateTool)}>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-2 items-center">
                        <Input
                            placeholder="Tool name"
                            {...register('name', { required: true })}
                            id="name"
                        />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <Button type="submit" disabled={!formState.isDirty} variant="default">
                            Save
                        </Button>
                        <Button onClick={() => navigate({ to: '../' })} variant="ghost" size="icon">
                            <X />
                        </Button>
                    </div>
                </div>
                <Separator className="my-5" />
                <div className="grid grid-cols-3 gap-10">
                    <div className="flex flex-col gap-5 col-span-2">
                        <div className="flex flex-col">
                            <p>Tool purpose</p>
                            <Textarea
                                rows={4}
                                placeholder="When should this tool be called?"
                                {...register('purpose', { required: true })}
                                id="name"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Parameters</p>
                            {parameters.map((_, index) => {
                                return (
                                    <Parameter
                                        key={index}
                                        index={index}
                                        {...{ parameters, setParameters, setUseCases }}
                                    />
                                )
                            })}
                            <Button
                                variant="outline"
                                type="button"
                                className="border-primary text-primary items-center w-40 mt-2"
                                onClick={() => handleAddParameter()}
                            >
                                Add a parameter <PlusIcon className="h-4 w-4 ml-2" />
                            </Button>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <p>Example Use Cases</p>
                            {useCases.map((useCase, index) => {
                                return (
                                    <UseCase
                                        key={index}
                                        index={index}
                                        {...{ useCase, useCases, setUseCases, parameters }}
                                    />
                                )
                            })}
                            <Button
                                variant="outline"
                                type="button"
                                className="border-primary text-primary items-center w-52 mt-2"
                                onClick={() => handleAddUseCase()}
                            >
                                Add example use case <PlusIcon className="h-4 w-4 ml-2" />
                            </Button>
                        </div>
                        <div className="flex flex-col">
                            <p>Tool Handling</p>
                            <div className="flex flex-col gap-1">
                                <p className="text-xs mt-2 text-foreground">API Endpoint</p>
                                <div className="flex flex-row gap-2">
                                    <Controller
                                        control={control}
                                        name={`httpMethod`}
                                        rules={{ required: true }}
                                        render={({ field }) => {
                                            const { ref: _, ...rest } = field
                                            return (
                                                <Select
                                                    onValueChange={(val) => {
                                                        field.onChange(val)
                                                        setToolHttpMethod(val)
                                                    }}
                                                    {...rest}
                                                >
                                                    <SelectTrigger className="w-24">
                                                        <SelectValue
                                                            className="bg-background"
                                                            placeholder="Method"
                                                        />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>HTTP Method</SelectLabel>
                                                            <SelectItem value="GET">GET</SelectItem>
                                                            <SelectItem value="POST">
                                                                POST
                                                            </SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            )
                                        }}
                                    />
                                    <Input
                                        {...register('url', { required: true })}
                                        placeholder="Url"
                                        onChange={(e) => setToolUrl(e.currentTarget.value)}
                                    />
                                </div>
                                <div className="flex flex-row gap-2 py-3">
                                    <div className="min-h-[150px] rounded-md w-full bg-gray-100/50 dark:bg-gray-100/5 p-2 flex flex-col gap-1">
                                        <p className="text-xs text-muted-foreground">Headers</p>
                                        {headers.map((header, index) => {
                                            return (
                                                <div key={index} className="flex flex-row gap-2">
                                                    {headers.length > 1 && (
                                                        <Button
                                                            type="button"
                                                            variant="ghost"
                                                            className="px-3 py-1 rounded-full"
                                                            onClick={() => {
                                                                setHeaders((prev) =>
                                                                    prev.filter(
                                                                        (_, i) => i !== index
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    )}
                                                    <Input
                                                        value={header.key}
                                                        onChange={(e) => {
                                                            setHeaders((prev) => {
                                                                prev[index].key =
                                                                    e.currentTarget.value
                                                                return [...prev]
                                                            })
                                                        }}
                                                        placeholder="Key"
                                                        className="bg-background"
                                                    />
                                                    <Input
                                                        value={header.value}
                                                        onChange={(e) => {
                                                            setHeaders((prev) => {
                                                                prev[index].value =
                                                                    e.currentTarget.value
                                                                return [...prev]
                                                            })
                                                        }}
                                                        placeholder="Value"
                                                        className="bg-background"
                                                    />
                                                </div>
                                            )
                                        })}
                                        <Button
                                            variant="outline"
                                            type="button"
                                            className="border-primary text-primary items-center w-32 mt-2"
                                            onClick={() =>
                                                setHeaders((prev) => [
                                                    ...prev,
                                                    { key: '', value: '' },
                                                ])
                                            }
                                        >
                                            Add header <PlusIcon className="h-4 w-4 ml-2" />
                                        </Button>
                                    </div>
                                    <ParametersCodeBlock
                                        langauge={codeBlockLanguage}
                                        valueToDisplay={codeBlockValue}
                                        codeBlockTitle={
                                            toolHttpMethod == 'POST'
                                                ? 'Body'
                                                : 'Url With Query Parameters'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cn(
                            'flex flex-col gap-5 h-fit',
                            displayPromptPreview() && 'h-fit'
                        )}
                    >
                        <div className="flex flex-col border-gray-200 dark:border-gray-400/15 rounded-md border h-full">
                            <div className="bg-gray-100 dark:bg-opacity-5 rounded-t-md p-2">
                                <p>Prompt Preview</p>
                            </div>
                            {displayPromptPreview() ? (
                                <p className="text-sm whitespace-pre-wrap text-pretty p-4 font-azeret text-[15px] text-[#3D3D39] dark:text-white">
                                    {generatePromptPreview(
                                        getValues('name'),
                                        getValues('purpose'),
                                        parameters,
                                        useCases
                                    )}
                                </p>
                            ) : (
                                <div className="bg-gray-200 w-[90%] h-[200px] rounded-md self-center my-5 text-center flex flex-col gap-2 justify-center dark:bg-opacity-5 p-2">
                                    <Info className="self-center" />
                                    <p className="text-sm">
                                        Complete the information of your tool to preview the prompt
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
