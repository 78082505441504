import { BaseNodeData } from '@/components/nodes'
import apiRequest from '@/lib/apiClient'
import { Memory } from './memory'
import { PipelineVersion } from './pipelineVersion'

export type PipelineForm = {
    name: string
    description: string
    steps: BaseNodeData[]
    projectId: string
    activeVersionId?: string
}

export type Pipeline = {
    id: string
    description: string
    name: string
    executionName: string
    createdAt: string
    updatedAt: string
    activeVersionId: string
    activeVersion: PipelineVersion
    versions: PipelineVersion[]
    executionStats: {
        successCount: number
        failureCount: number
    }
    projectId: string
}

export type PipelineList = {
    items: Pipeline[]
    totalCount: number
}

export enum StepType {
    DataSearch = 'dataSearch',
    AIOperation = 'AIOperation',
    InputStep = 'inputStep',
    OutputStep = 'outputStep',
    PythonStep = 'pythonStep',
    ExecutePipelineStep = 'executePipelineStep',
    MemoryLoadStep = 'memoryLoadStep',
    MemoryStoreStep = 'memoryStoreStep',
}
export const NodeTypeMap: Record<StepType, string> = {
    [StepType.AIOperation]: 'modelNode',
    [StepType.DataSearch]: 'datasetNode',
    [StepType.InputStep]: 'inputNode',
    [StepType.OutputStep]: 'outputNode',
    [StepType.ExecutePipelineStep]: 'pipelineNode',
    [StepType.PythonStep]: 'pythonCodeNode',
    [StepType.MemoryLoadStep]: 'memoryNode',
    [StepType.MemoryStoreStep]: 'memoryNode',
}

export type BaseStepData = {
    stepId: string
    position: {
        id?: string
        x: number
        y: number
    }
    dependencies: string[]
    projectId?: string
    pipelineId: string
}

export type InputStepData = BaseStepData & {
    stepType: StepType.InputStep
}

export type OutputStepData = BaseStepData & {
    stepType: StepType.OutputStep
}

export type ModelStepData = BaseStepData & {
    stepType: StepType.AIOperation
    promptId: string | null
    temperature: number
    includeDateTimeContext: boolean
    modelId: string
    selectedPromptVersion: string | null
}

export type DatasetStepData = BaseStepData & {
    stepType: StepType.DataSearch
    dataSource: string
    topK: string
    relevanceThreshold: string
    isRetrieveNeighboringChunks: boolean
    dataSourceType: string
}

export type PipelineStepData = BaseStepData & {
    stepType: StepType.ExecutePipelineStep
    executePipelineId: string
    selectedPipelineVersion: string
    versionNumber: number
}

export type PythonCodeStepData = BaseStepData & {
    stepType: StepType.PythonStep
    code: string
}

export type MemoryStepData = BaseStepData & {
    stepType: StepType.MemoryLoadStep | StepType.MemoryStoreStep
    memory: string
} & Memory

export type PipelineStep =
    | InputStepData
    | OutputStepData
    | ModelStepData
    | DatasetStepData
    | PipelineStepData
    | PythonCodeStepData
    | MemoryStepData

export const createPipeline = async (pipeline: PipelineForm): Promise<Pipeline> => {
    return apiRequest('/PipelinesConfig', { method: 'POST', body: pipeline })
}
export const updatePipeline = async (pipeline: PipelineForm & { id: string }): Promise<void> => {
    return apiRequest(`/PipelinesConfig/${pipeline.id}`, { method: 'PUT', body: pipeline })
}

export const getAllPipelines = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineList> => {
    return apiRequest('/PipelinesConfig', { queryParams })
}

export const getPipelineById = async (id: string): Promise<Pipeline> => {
    return apiRequest(`/PipelinesConfig/${id}`)
}

export const deletePipeline = async (id: string): Promise<void> => {
    return apiRequest(`/PipelinesConfig/${id}`, { method: 'DELETE' })
}

export const importPipeline = async (pipeline: PipelineForm) => {
    return apiRequest('/PipelineImport', { method: 'POST', body: pipeline })
}
