import apiRequest from '@/lib/apiClient'
import { Dataset } from './datasets'

export type DataSourceGroup = {
    id: string
    projectId: string
    name: string
    description: string
    dataSources: Dataset[]
}

export const CreateDataSourceGroup = async (data: DataSourceGroup): Promise<DataSourceGroup> => {
    return apiRequest('/DataSourceGroup', { method: 'POST', body: data })
}

export const DeleteDataSourceGroup = async (id: string): Promise<void> => {
    return apiRequest(`/DataSourceGroup/${id}`, { method: 'DELETE' })
}

export const GetDataSourceGroupById = async (id: string): Promise<DataSourceGroup> => {
    return apiRequest(`/DataSourceGroup/${id}`)
}

export const GetAllDataSourceGroups = async (
    projectId?: string,
    userId?: string
): Promise<DataSourceGroup[]> => {
    const params = new URLSearchParams()
    if (projectId) params.append('projectId', projectId)
    if (userId) params.append('userId', userId)
    return apiRequest(`/DataSourceGroup${params.toString() ? `?${params.toString()}` : ''}`)
}
