import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import ConsumptionTable from '@/components/home/consumption/Consumption'

export const Route = createFileRoute('/_mainLayout/_homeLayout/consumption')({
    component: Consumption,
    // //@ts-ignore
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    } => ({
        projectIds: search.projectIds as string,
        pageNumber: search.pageNumber as number,
        pageSize: search.pageSize as number,
        sortBy: search.sortBy as string,
        startTime: search.startTime as number,
        endTime: search.endTime as number,
    }),
})

function Consumption() {
    const queryParams = Route.useSearch()
    return <ConsumptionTable queryParams={queryParams} />
}
