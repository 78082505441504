import {
    ChunkingStrategyEnum,
    CreateDataset,
    DataSourceConfiguration,
    DataSourceType,
    Dataset,
    getDataSourceForms,
} from '@/api/datasets'
import {
    DataSourceFormField,
    FormField,
    GroupedFields,
} from '@/components/newDataSource/dataSourceFormBuilder'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { cn } from '@/lib/utils'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, createFileRoute, useRouter } from '@tanstack/react-router'
import { X } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { z } from 'zod'

const newDataSourceSearchSchema = z.object({
    connectorId: z.string(),
})

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/datasets/new')({
    component: () => {
        return <NewDataset />
    },
    validateSearch: (search: Record<string, unknown>) => newDataSourceSearchSchema.parse(search),
    loader: async () => getDataSourceForms(),
})

function NewDataset() {
    const { t } = useTranslation()
    const router = useRouter()
    const params = Route.useParams()
    const search = Route.useSearch()
    const dataSourceForm = Route.useLoaderData()

    const dataSourceType = search.connectorId

    const renderField = (field: DataSourceFormField) => (
        <FormField
            field={field}
            register={register}
            setValue={setValue}
            getValues={getValues}
            handleFileChange={handleFileChange}
        />
    )

    const { control, formState, handleSubmit, watch, register, reset, setValue, getValues } =
        useForm<Dataset>({
            defaultValues: {
                databaseType: 'mongodb',
                dataSourceType: dataSourceType || '',
                embeddingProvider: 'OpenAI',
                projectId: params.projectId,
                //@ts-ignore
                configuration: {
                    ...dataSourceForm[dataSourceType].fields.reduce(
                        (acc, field) => {
                            acc[field.name] = ''
                            return acc
                            //@ts-ignore
                        },
                        {} as Record<string, string>
                    ),
                    //@ts-ignore
                    source: dataSourceForm[dataSourceType].source,
                    type: dataSourceType,
                },
                chunkingConfig: {
                    strategyType: ChunkingStrategyEnum.markdown,
                    chunkSize: 1000,
                    chunkOverlap: 200,
                },
                isUserSpecific: false,
            },
        })

    useEffect(() => {
        if (dataSourceType) {
            reset({
                databaseType: 'mongodb',
                embeddingProvider: 'OpenAI',
                dataSourceType: dataSourceType,
                configuration: {
                    ...dataSourceForm[dataSourceType].fields.reduce(
                        (acc, field) => {
                            acc[field.name] = ''
                            return acc
                            //@ts-ignore
                        },
                        {} as Record<string, string>
                    ),
                    //@ts-ignore
                    source: dataSourceForm[dataSourceType].source,
                    type: dataSourceType,
                },
                projectId: params.projectId,
                chunkingConfig: {
                    strategyType: ChunkingStrategyEnum.markdown,
                    chunkSize: 1000,
                    chunkOverlap: 200,
                },
                isUserSpecific: false,
            })
        }
    }, [dataSourceType, reset])

    const selectedDatabase = watch('databaseType')
    const selectedDataSourceType = watch('dataSourceType') as DataSourceType

    const queryClient = useQueryClient()
    const datasetMutation = useMutation({
        mutationFn: CreateDataset,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS_INFINITE],
            })
            toast({
                title: t('success_title'),
                description: t('createDataset.success_description'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        fieldName: string
    ) => {
        const file = event.target.files?.[0]
        if (file) {
            try {
                const content = await readFileAsText(file)
                const currentConfig = getValues('configuration')
                const updatedConfig: DataSourceConfiguration = {
                    ...currentConfig,
                    [fieldName]: content,
                }
                setValue('configuration', updatedConfig)
            } catch (error) {
                console.error('Error reading file:', error)
            }
        }
    }

    const readFileAsText = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target?.result && typeof event.target.result === 'string') {
                    resolve(event.target.result)
                } else {
                    reject(new Error('Failed to read file as text'))
                }
            }
            reader.onerror = reject
            reader.readAsText(file)
        })
    }

    const handleCreateDataset = async (data: Dataset) => {
        try {
            const newDataSet = await datasetMutation.mutateAsync(dataToSend(data) as Dataset)
            await router.invalidate()
            if (newDataSet.dataSourceType == DataSourceType.FILE_UPLOAD) {
                router.navigate({
                    to: '/$projectId/datasets/$datasetId/edit',
                    params: { datasetId: newDataSet.id, projectId: params.projectId },
                })
            } else {
                router.navigate({
                    to: '/$projectId/datasets',
                    params: { projectId: params.projectId },
                })
            }
        } catch (error: any) {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        }
    }

    const dataToSend = (data: Dataset) => {
        if (selectedDataSourceType == DataSourceType.CLOUD_KIT) {
            return {
                ...data,
                configuration: {
                    ...data.configuration,
                    isEcKeyFilePath: false,
                },
                configurationJson: JSON.stringify({
                    ...data.configuration,
                    isEcKeyFilePath: false,
                }),
            }
        } else {
            return {
                ...data,
                configurationJson: JSON.stringify(data.configuration),
            }
        }
    }

    const groupFields = (fields: DataSourceFormField[]): GroupedFields => {
        return fields.reduce<GroupedFields>((acc, field) => {
            if (field.group === null) {
                if (!acc.ungrouped) {
                    acc.ungrouped = []
                }
                acc.ungrouped.push(field)
            } else {
                const group = field.group || 'Default'
                if (!acc[group]) {
                    acc[group] = []
                }
                acc[group].push(field)
            }
            return acc
        }, {})
    }

    return (
        <div className="px-4">
            <div className="flex flex-row justify-between mb-3">
                <p className="text-xl">{t('newDataSource')}</p>
                <Link to="../">
                    <X />
                </Link>
            </div>
            <div className={'grid grid-cols-4 min-h-72 max-h-[90vh]'}>
                <div></div>
                <div className={'col-span-4 overflow-y-auto'}>
                    <form onSubmit={handleSubmit(handleCreateDataset)} id="create_new_data_set">
                        <div className={'flex flex-col gap-4'}>
                            <div>
                                <Label htmlFor="dataSourceType">
                                    {t('createDataset.dataSource')}
                                </Label>
                                <Controller
                                    control={control}
                                    name="dataSourceType"
                                    render={({ field }) => (
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                            disabled
                                        >
                                            <SelectTrigger>
                                                <SelectValue
                                                    placeholder={t(
                                                        'createDataset.selectDataSource'
                                                    )}
                                                />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectLabel>
                                                        {t('createDataset.dataSource')}
                                                    </SelectLabel>
                                                    {Object.keys(dataSourceForm).map((type) => {
                                                        let displayType = type.endsWith('Loader')
                                                            ? type.slice(0, -6)
                                                            : type
                                                        displayType = displayType
                                                            .replace(
                                                                /([A-Z])([A-Z][a-z])|([a-z])([A-Z])/g,
                                                                '$1$3 $2$4'
                                                            )
                                                            .trim()
                                                        displayType =
                                                            displayType.charAt(0).toUpperCase() +
                                                            displayType.slice(1)
                                                        return (
                                                            <SelectItem key={type} value={type}>
                                                                {displayType}
                                                            </SelectItem>
                                                        )
                                                    })}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </div>
                            <div className="flex justify-between items-center">
                                <div
                                    className={
                                        selectedDataSourceType === DataSourceType.FILE_UPLOAD
                                            ? 'w-3/4'
                                            : 'w-full'
                                    }
                                >
                                    <Label htmlFor="name">{t('createDataset.name')}</Label>
                                    <Input
                                        id="name"
                                        className={cn(
                                            datasetMutation.isError && 'border-error-message'
                                        )}
                                        {...register('name', { required: true })}
                                        required
                                        pattern="[ A-Za-z0-9\-_.~]+"
                                        title={t('createDataset.namePatternTitle')}
                                    />
                                    {datasetMutation.isError && (
                                        <p className="text-sm text-error-message pt-1">
                                            {datasetMutation.error.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {selectedDataSourceType === DataSourceType.FILE_UPLOAD && (
                                <div className="flex items-center">
                                    <Label htmlFor="userSpecific" className="mr-2">
                                        User Specific
                                    </Label>
                                    <Controller
                                        name="isUserSpecific"
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                id="userSpecific"
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            {selectedDataSourceType && dataSourceForm[selectedDataSourceType] && (
                                <>
                                    {groupFields(dataSourceForm[selectedDataSourceType].fields)
                                        .ungrouped && (
                                        <div className="flex flex-col gap-4 mb-4">
                                            {groupFields(
                                                dataSourceForm[selectedDataSourceType].fields
                                            )
                                                .ungrouped.filter(
                                                    (field) => field.name !== 'isEcKeyFilePath'
                                                )
                                                .map((field) => (
                                                    <div key={field.name}>
                                                        <Label htmlFor={field.name}>
                                                            {field.label}
                                                        </Label>
                                                        {renderField(field)}
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    <Accordion type="multiple" className="w-full">
                                        {Object.entries(
                                            groupFields(
                                                dataSourceForm[selectedDataSourceType].fields
                                            )
                                        )
                                            .filter(([group]) => group !== 'ungrouped')
                                            .map(([group, fields]) => (
                                                <AccordionItem value={group} key={group}>
                                                    <AccordionTrigger>
                                                        <h4 className="text-lg">{group}</h4>
                                                    </AccordionTrigger>
                                                    <AccordionContent>
                                                        <div className="flex flex-col gap-4">
                                                            {fields.map((field) => (
                                                                <div
                                                                    key={field.name}
                                                                    className="flex flex-col gap-2"
                                                                >
                                                                    <Label htmlFor={field.name}>
                                                                        {field.label}
                                                                    </Label>
                                                                    {renderField(field)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </AccordionContent>
                                                </AccordionItem>
                                            ))}
                                    </Accordion>
                                </>
                            )}
                            <Accordion type="single" collapsible className="w-full">
                                <AccordionItem value="ingest-setup">
                                    <AccordionTrigger>
                                        <h4 className="text-lg">Ingest Setup</h4>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        <div>
                                            <Label htmlFor="databaseType">
                                                {t('createDataset.database')}
                                            </Label>
                                            <Controller
                                                control={control}
                                                name="databaseType"
                                                rules={{ required: true }}
                                                render={({ field }) => {
                                                    const { ref: _, ...rest } = field
                                                    return (
                                                        <Select
                                                            defaultValue="mongodb"
                                                            onValueChange={field.onChange}
                                                            {...rest}
                                                        >
                                                            <SelectTrigger>
                                                                <SelectValue
                                                                    placeholder={t(
                                                                        'createDataset.selectDatabase'
                                                                    )}
                                                                />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectLabel>
                                                                        {t(
                                                                            'createDataset.database'
                                                                        )}
                                                                    </SelectLabel>
                                                                    <SelectItem value="mongodb">
                                                                        MongoDB
                                                                    </SelectItem>
                                                                    <SelectItem value="pinecone">
                                                                        Pinecone
                                                                    </SelectItem>
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    )
                                                }}
                                            />
                                        </div>
                                        {selectedDatabase === 'pinecone' && (
                                            <div className={'flex justify-between'}>
                                                <div className={'w-full mr-2'}>
                                                    <Label htmlFor="pc_api_key">
                                                        {t('createDataset.pineconeApiKey')}
                                                    </Label>
                                                    <Input
                                                        id="pc_api_key"
                                                        type="text"
                                                        required
                                                        //@ts-ignore
                                                        style={{ WebkitTextSecurity: 'disc' }}
                                                        autoComplete="off"
                                                        {...register('pineconeApiKey', {
                                                            required: true,
                                                        })}
                                                    />
                                                </div>
                                                <div className={'w-full ml-2'}>
                                                    <Label htmlFor="pc_index_name">
                                                        {t('createDataset.pineconeIndexName')}
                                                    </Label>
                                                    <Input
                                                        id="pc_index_name"
                                                        type="text"
                                                        required
                                                        {...register('pineconeIndexName', {
                                                            required: true,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <Label htmlFor="embeddingProvider">
                                                {t('createDataset.embeddingModel')}
                                            </Label>
                                            <Controller
                                                control={control}
                                                name="embeddingProvider"
                                                rules={{ required: true }}
                                                render={({ field }) => {
                                                    const { ref: _, ...rest } = field
                                                    return (
                                                        <Select
                                                            onValueChange={field.onChange}
                                                            {...rest}
                                                        >
                                                            <SelectTrigger>
                                                                <SelectValue
                                                                    placeholder={t(
                                                                        'select_embedding_model'
                                                                    )}
                                                                />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectLabel>
                                                                        {t('embedding_model')}
                                                                    </SelectLabel>
                                                                    <SelectItem value="OpenAI">
                                                                        OpenAi Text
                                                                        Embedding-3-Small
                                                                    </SelectItem>
                                                                    <SelectItem value="AzureVision">
                                                                        Azure Vision
                                                                    </SelectItem>
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div className={'mt-4'}>
                            <Button disabled={!formState.isValid} type="submit">
                                {t('createDataset.create')}
                            </Button>
                        </div>
                    </form>
                </div>
                <div></div>
            </div>
        </div>
    )
}
