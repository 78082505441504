import { Prompt } from '@/api/prompts.ts'
import apiRequest from '@/lib/apiClient'

export interface CreateModelFromLibraryRequest {
    libraryModelId: string
    projectId: string
    inputType: string
    promptId?: string | null
    displayName: string
    tokenUnits: string
}

export type Model = {
    id: string
    displayName: string
    modelName: string
    promptId?: string | null
    sourceType: 'UserProvided' | 'Library'
    provider: string
    type: string
    tenantId?: string
    projectId: string
    systemPrompt?: Prompt
    createdAt?: string
    updatedAt?: string
    hasToolSupport: boolean
    userProvidedDetails: ModelUserProvidedDetails
    libraryModelId?: string
}

export type ModelUserProvidedDetails = {
    url: string
    credentialsId?: string
    deploymentType: 'External' | 'Internal' | 'Marketplace'
    connectionString?: string
    containerName?: string
    deployedKey?: string
    deployedUrl?: string
    state?: string
    uploadedContainerId?: string
    inputTokenPrice?: number
    outputTokenPrice?: number
    tokenUnits?: string
}

export type AzureAiStudioModel = {
    model_name: string
    model_type: string
    model_provider_name: number
    served_model_name: string
    served_model_type: string
}

export type AnthropicModel = {
    id: string
    object: string
    created: number
    owned_by: string
}

export type ModelList = {
    items: Model[]
    totalCount: number
}

export type OllamaModel = {
    name: string
    model: string
    modified_at: string
    size: number
    digest: string
    details: {
        parent_model: string
        format: string
        family: string
        families: string[]
    }
    parameter_size: string
    quantization_level: string
}

export type OpenAIModel = {
    id: string
    object: string
    created: number
    owned_by: string
}

export type HuggingFaceModel = {
    _id: string
    id: string
    likes: number
    private: boolean
    downloads: number
    tags: string[]
    pipeline_tag?: string
    library_name: string
    createdAt: string
    modelId: string
}

export type HuggingFaceQuery = {
    search?: string
    author?: string
    filter?: string
    sort?: string
    direction?: string
    limit?: string
    full?: string
    config?: string
}

export type HuggingFaceStatus = {
    loaded: boolean
    state: string
    compute_type: string
    framework: string
}

export const CreateModel = async (data: Omit<Model, 'id' | 'last_modified' | 'credentials'>) => {
    return apiRequest('/Models', { method: 'POST', body: data })
}

export const CreateModelLibrary = async (data: CreateModelFromLibraryRequest) => {
    return apiRequest('/Models/Library', { method: 'POST', body: data })
}

export const UpdateModel = async (data: Model) => {
    return apiRequest(`/Models/${data.id}`, { method: 'PUT', body: data })
}

export const GetAllModels = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<ModelList> => {
    return apiRequest('/Models', { queryParams })
}

export const DeleteModel = async (id: string) => {
    return apiRequest(`/Models/${id}`, { method: 'DELETE' })
}

export const GetModelById = async (id: string): Promise<Model> => {
    return apiRequest(`/Models/${id}`)
}

export const GetDiscoveredModels = async (
    url: string,
    apiKey: string
): Promise<{ models: OllamaModel[]; data: OpenAIModel[] }> => {
    try {
        return await apiRequest('/ModelDiscovery/DiscoverModels', {
            queryParams: { url, apiKey },
        })
    } catch {
        return { models: [], data: [] }
    }
}

export const GetAllHuggingFaceModels = async (
    qs?: HuggingFaceQuery
): Promise<HuggingFaceModel[]> => {
    let url = `${import.meta.env.VITE_BASE_PATH}/ModelDiscovery/HuggingFace`
    if (qs) {
        if (qs.search === undefined) delete qs.search
        const params = new URLSearchParams(qs)
        url += '?' + params.toString()
    }
    return apiRequest(url)
}

export const GetHuggingFaceModelById = async (modelId: string): Promise<HuggingFaceModel> => {
    return apiRequest(`/ModelDiscovery/HuggingFace/details/${encodeURIComponent(modelId)}`)
}

export const GetHuggingFaceModelStatusById = async (
    modelId: string
): Promise<HuggingFaceStatus> => {
    return apiRequest(`/ModelDiscovery/HuggingFace/status/${encodeURIComponent(modelId)}`)
}

export const ReprocessUploadedModel = async (modelId: string) => {
    return apiRequest(`/ModelUpload/${modelId}`, { method: 'POST' })
}
