import apiRequest from '@/lib/apiClient.ts'
import { type paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'

export type AiOperationsExecution = {
    executionDateTime: string
    tenantId: string
    providerType: string
    modelName: string
    pipelineId: string
    pipelineName: string
    pipelineVersion: string
    projectId: string
    projectName: string
    executionId: string
    executionSourceType: string
    executionUser?: string
    totalTokenAmountConsumed: string
    inputTokenAmountConsumed: string
    outputTokenAmountConsumed: string
    totalTokenCountConsumed: number
    inputTokenCountConsumed: number
    outputTokenCountConsumed: number
    balanceUsed: string
}

export interface GetAllExecutionsResponseV1 {
    items: AiOperationsExecution[]
    totalCount: number
}

export interface ConsumptionFilterOptions {
    projectIds?: string[]
    executionDate?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
}

interface ExecutionQueryParams {
    descending?: string
    startTime?: string
    endTime?: string
    limit?: string
    offset?: string
    projectIds?: string
}

export const getAllExecutions = async (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
): Promise<GetAllExecutionsResponseV1> => {
    const apiQueryParams: Partial<ExecutionQueryParams> = {
        descending: String(queryParams?.sortDirection === 'DESC'),
        startTime: queryParams?.startTime?.toString(),
        endTime: queryParams?.endTime?.toString(),
        limit: queryParams?.pageSize?.toString(),
    }

    if (queryParams?.pageNumber && queryParams?.pageSize) {
        apiQueryParams.offset = String(queryParams.pageSize * (queryParams.pageNumber - 1))
    }

    if (queryParams?.projectIds) {
        apiQueryParams.projectIds = queryParams.projectIds
    }

    // Remove undefined values
    const cleanedParams = Object.fromEntries(
        Object.entries(apiQueryParams).filter(([_, v]) => v !== undefined)
    )

    return apiRequest('/marketplace/v1/AIOperationExecutions', {
        queryParams: cleanedParams,
    })
}

export const downloadOperationsCsv = (
    queryParams?: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
): Promise<string> => {
    const apiQueryParams: Partial<ExecutionQueryParams> = {
        descending: String(
            queryParams?.sortDirection ? queryParams.sortDirection === 'DESC' : true
        ),
        startTime: queryParams?.startTime?.toString(),
        endTime: queryParams?.endTime?.toString(),
        limit: queryParams?.pageSize?.toString(),
    }

    if (queryParams?.pageNumber && queryParams?.pageSize) {
        apiQueryParams.offset = String(queryParams.pageSize * (queryParams.pageNumber - 1))
    }

    if (queryParams?.projectIds) {
        apiQueryParams.projectIds = queryParams.projectIds
    }

    // Remove undefined values
    const cleanedParams = Object.fromEntries(
        Object.entries(apiQueryParams).filter(([_, v]) => v !== undefined)
    )
    return apiRequest('/marketplace/v1/AIOperationExecutions/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: cleanedParams,
    })
}
