import {
    CustomerTelemetry,
    SyslogConfiguration,
    getCustomerTelemetryConfiguration,
    putCustomerTelemetry,
} from '@/api/customerTelemetry.ts'
import SelectOption from '@/components/settings/SelectOption.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Switch } from '@/components/ui/switch.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'

const SIEM: React.FC = () => {
    const [enabled, setEnabled] = React.useState(false)
    const [useTLS, setUseTLS] = React.useState(false)
    const [useTcp, setUseTcp] = React.useState(true)
    const [useRfc5424, setUseRfc5424] = React.useState(true)
    const [ignoreSslErrors, setIgnoreSslErrors] = React.useState(false)
    const [tenantId, setTenantId] = React.useState<string>()
    const { t } = useTranslation()
    const getTelemetryQuery = useQuery<CustomerTelemetry>({
        queryKey: [QueryKeys.TELEMETRY],
        queryFn: getCustomerTelemetryConfiguration,
    })
    const { handleSubmit, register, setValue, formState } = useForm<SyslogConfiguration>({
        mode: 'onChange',
        defaultValues: React.useMemo(() => {
            return getTelemetryQuery.data?.telemetryConfiguration?.syslogConfiguration ?? {}
        }, [getTelemetryQuery.data]),
    })
    const telemetryMutation = useMutation({
        mutationFn: (data: CustomerTelemetry) => putCustomerTelemetry(data),
        onSuccess: () => {
            toast({
                title: t('success_title'),
                description: 'Telemetry settings updated successfully',
            })
        },
    })

    useEffect(() => {
        if (getTelemetryQuery.data?.telemetryConfiguration?.syslogConfiguration) {
            const syslogConfig = getTelemetryQuery.data.telemetryConfiguration?.syslogConfiguration
            setTenantId(getTelemetryQuery.data.tenantId)
            setEnabled(getTelemetryQuery.data.enableTelemetry ?? false)
            setUseTLS(syslogConfig.useTls ?? false)
            setUseTcp(syslogConfig.useTcp ?? true)
            setUseRfc5424(syslogConfig.useRfc5424 ?? true)
            setIgnoreSslErrors(syslogConfig.ignoreSslErrors ?? false)
            setValue('hostName', syslogConfig.hostName)
            setValue('port', syslogConfig.port)
        }
    }, [getTelemetryQuery.data])

    useEffect(() => {
        if (getTelemetryQuery.data) {
            setTenantId(getTelemetryQuery.data.tenantId)
        }
    }, [getTelemetryQuery.isLoading, getTelemetryQuery.data])

    useEffect(() => {
        setValue('enable', enabled)
    }, [enabled])

    useEffect(() => {
        setValue('useTcp', useTcp)
    }, [useTcp])

    useEffect(() => {
        setValue('useRfc5424', useRfc5424)
    }, [useRfc5424])

    useEffect(() => {
        setValue('useTls', useTLS)
    }, [useTLS])

    useEffect(() => {
        setValue('ignoreSslErrors', ignoreSslErrors)
    }, [ignoreSslErrors])

    const onProtocolSelect = (value: string) => {
        setUseTcp(value === 'tcp')
    }

    const onFormatSelect = (value: string) => {
        setUseRfc5424(value === 'rfc5424')
    }

    const onFormSubmit = (data: SyslogConfiguration) => {
        const payload: CustomerTelemetry = {
            tenantId: tenantId ?? '',
            enableTelemetry: enabled,
            telemetryConfiguration: enabled
                ? {
                      syslogConfiguration: {
                          ...data,
                          port: +data.port,
                      },
                  }
                : undefined,
        }
        telemetryMutation.mutate(payload)
    }

    return (
        <div className="flex flex-col gap-6 mt-6">
            <div className={'flex gap-6'}>
                <h1 className={'text-2xl font-gilroy-regular'}>{t('siem.syslog')}</h1>
                <div className={'flex gap-2 items-center'}>
                    <span>{t('siem.enabled')}</span>
                    <Switch id={'enabled'} checked={enabled} onCheckedChange={setEnabled} />
                </div>
            </div>
            <div>
                <div className={'mb-4'}>
                    <p>{t('siem.description')}</p>
                </div>
                {enabled && (
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className={'bg-card w-[65%] p-4 rounded-lg flex flex-col gap-4'}>
                            <div>
                                <label htmlFor="hostName">{t('siem.server_url')}</label>
                                <Input
                                    id="hostName"
                                    className={'bg-background'}
                                    type="text"
                                    placeholder={t('siem.server_url_placeholder')}
                                    {...register('hostName', { required: true })}
                                />
                            </div>
                            <div className={'flex justify-between gap-4'}>
                                <div className={'w-full'}>
                                    <label htmlFor="protocol">{t('siem.protocol')}</label>
                                    <div className={'flex gap-4'}>
                                        <SelectOption
                                            checked={useTcp}
                                            value={'tcp'}
                                            onChange={onProtocolSelect}
                                            label={'TCP'}
                                        />
                                        <SelectOption
                                            checked={!useTcp}
                                            value={'udp'}
                                            onChange={onProtocolSelect}
                                            label={'UDP'}
                                        />
                                    </div>
                                </div>
                                <div className={'w-full'}>
                                    <label htmlFor="format">{t('siem.format')}</label>
                                    <div className={'flex gap-4'}>
                                        <SelectOption
                                            checked={useRfc5424}
                                            value={'rfc5424'}
                                            onChange={onFormatSelect}
                                            label={'RFC5424'}
                                        />
                                        <SelectOption
                                            checked={!useRfc5424}
                                            value={'rfc3164'}
                                            onChange={onFormatSelect}
                                            label={'RFC3164'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <h3>TLS</h3>
                            <div className={'flex flex-col gap-6 bg-background rounded-lg p-4'}>
                                <div className={'flex justify-between items-center'}>
                                    <div className={'flex flex-col'}>
                                        <label htmlFor="useTLS">{t('siem.use_tls')}</label>
                                        <span className={'text-sm'}>
                                            {t('siem.additional_tls')}
                                        </span>
                                    </div>
                                    <Switch
                                        id="useTLS"
                                        checked={useTLS}
                                        onCheckedChange={setUseTLS}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="tlsPort">{t('siem.tls_port')}</label>
                                    <Input
                                        id="tlsPort"
                                        className={'bg-background'}
                                        type="number"
                                        placeholder={'Port goes here'}
                                        {...register('port')}
                                    />
                                </div>
                                <div className={'flex items-center gap-1'}>
                                    <input
                                        id="ignoreCert"
                                        className={'bg-background'}
                                        type="checkbox"
                                        checked={ignoreSslErrors}
                                        onChange={(e) => setIgnoreSslErrors(e.target.checked)}
                                    />
                                    <label htmlFor="ignoreCert">Ignore TLS Certificate</label>
                                </div>
                            </div>
                            <div className={'flex justify-end'}>
                                <Button disabled={!formState.isValid}>{t('save')}</Button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default SIEM
