import { createFileRoute, Link, Outlet, RoutePaths } from '@tanstack/react-router'
import { routeTree } from '@/routeTree.gen.ts'
import * as Tabs from '@radix-ui/react-tabs'
import React from 'react'
import Activity from '@/assets/icons/activity.svg?react'
import BarChartCircle from '@/assets/icons/bar-chart-circle.svg?react'
import BankNote from '@/assets/icons/bank-note.svg?react'

export const Route = createFileRoute('/_mainLayout/_homeLayout')({
    component: HomeLayout,
})

function HomeLayout() {
    const TABS: {
        path: RoutePaths<typeof routeTree>
        label: string
        icon: JSX.Element
        value: string
    }[] = [
        {
            path: '/overview',
            label: 'Overview',
            icon: <BarChartCircle className="w-6 h-6 mr-2 -ml-1" />,
            value: 'overview',
        },
        {
            path: '/feed',
            label: 'Feed',
            icon: <Activity className="w-6 h-6 mr-2 -ml-1" />,
            value: 'feed',
        },
        {
            path: '/consumption',
            label: 'Consumption',
            icon: <BankNote className="w-6 h-6 mr-2 -ml-1" />,
            value: 'consumption',
        },
    ]

    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs.Root defaultValue="overview" className="flex">
                <Tabs.List className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    {TABS.map((tab) => (
                        <TabLink key={tab.path} {...tab} />
                    ))}
                </Tabs.List>
            </Tabs.Root>
            <div className={'p-1'}>
                <Outlet />
            </div>
        </div>
    )
}

const TabLink: React.FC<{
    path: RoutePaths<typeof routeTree>
    label: string
    value: string
    icon: JSX.Element
}> = ({ path, label, value, icon }) => {
    return (
        <Link
            to={path}
            activeProps={{
                className:
                    'text-primary shadow-primary focus:shadow-primary relative shadow-[inset_0_-1px_0_0,0_1px_0_0]',
            }}
        >
            <Tabs.Trigger
                className="bg-background px-1 gap-2 group h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md outline-none cursor-default"
                value={value}
            >
                <div className={'flex gap-1 pr-1'}>
                    {icon}
                    {label}
                </div>
            </Tabs.Trigger>
        </Link>
    )
}
