import apiRequest from '@/lib/apiClient.ts'

export type SyslogConfiguration = {
    enable: boolean
    hostName: string
    port: number
    useRfc5424: boolean
    useTcp: boolean
    useTls: boolean
    ignoreSslErrors: boolean
}

export type CustomerTelemetryConfiguration = {
    syslogConfiguration?: SyslogConfiguration
}

export type CustomerTelemetry = {
    tenantId: string
    enableTelemetry: boolean
    telemetryConfiguration?: CustomerTelemetryConfiguration
}

export const getCustomerTelemetryConfiguration = async () => {
    return await apiRequest<CustomerTelemetry>('/CustomerTelemetry')
}

export const putCustomerTelemetry = async (customerTelemetry: CustomerTelemetry) => {
    return await apiRequest<CustomerTelemetry>('/CustomerTelemetry', {
        method: 'PUT',
        body: customerTelemetry,
    })
}
