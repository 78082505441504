import apiRequest from '@/lib/apiClient'
export type Prompt = {
    id: string
    name: string
    activeVersionId: string
    activeVersion: PromptVersion
    versions: PromptVersion[]
    createdAt: string
    updatedAt: string
    projectId: string
}

export type PromptVersion = {
    id: string
    versionNumber: number
    messages: PromptMessage[]
    isLatest: boolean
    createdAt: string
    updatedAt: string
    projectId: string
    versionChangeDescription: string
}

export type PromptMessage = {
    text: string
    order: number
}

export type CreatePromptDto = {
    name: string
    messages: { text: string; order: number }[]
    projectId: string
}

export type UpdatePromptDto = {
    id: string
    activeVersionId: string
    projectId: string
}

export type CreatePromptVersion = {
    messages: PromptMessage[]
    promptId: string
}

export type PromptList = {
    items: Prompt[]
    totalCount: number
}

export const getAllPrompts = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PromptList> => {
    return apiRequest('/Prompts', { queryParams })
}

export const createPrompt = async (promptDto: CreatePromptDto): Promise<void> => {
    return apiRequest('/Prompts', { method: 'POST', body: promptDto })
}

export const updatePrompt = async (id: string, promptDto: UpdatePromptDto): Promise<void> => {
    return apiRequest(`/Prompts/${id}`, { method: 'PUT', body: promptDto })
}

export const createPromptVersion = async (
    promptVersion: CreatePromptVersion
): Promise<PromptVersion> => {
    return apiRequest('/PromptVersion', { method: 'POST', body: promptVersion })
}

export const getPromptById = async (id: string): Promise<Prompt> => {
    return apiRequest(`/Prompts/${id}`)
}

export const deletePrompt = async (id: string): Promise<void> => {
    return apiRequest(`/Prompts/${id}`, { method: 'DELETE' })
}
