import { Credential } from '@/api/credentials.ts'
import apiRequest from '@/lib/apiClient'
import { ApiKey } from './apiKeys'
import { Dataset } from './datasets'
import { Memory } from './memory'
import { Model } from './models'
import { Pipeline } from './pipelinesConfig'
import { Prompt } from './prompts'

interface BaseProject {
    id: string
    name: string
    createdAt: string
    updatedAt: string
    description?: string
    projectIcon?: string
    projectIconId?: string
    projectType: string
}

export type Project = BaseProject & {
    pipelines: Pipeline[]
    models: Model[]
    dataSources: Dataset[]
    prompts: Prompt[]
    apiKeys: ApiKey[]
    memories: Memory[]
    credentials: Credential[]
}

export const getAllProjects = async (): Promise<Project[]> => {
    return apiRequest('/Project')
}

export const createProject = async (project: Project): Promise<Project> => {
    return apiRequest('/Project', { method: 'POST', body: project })
}
export const updateProject = async (project: Project): Promise<void> => {
    return apiRequest(`/Project/${project.id}`, { method: 'PUT', body: project })
}

export const getProjectById = async (id: string): Promise<Project> => {
    return apiRequest(`/Project/${id}`)
}

export const deleteProject = async (id: string): Promise<void> => {
    return apiRequest(`/Project/${id}`, { method: 'DELETE' })
}
