import apiRequest from '@/lib/apiClient'

export interface Config {
    clientId: string
    clientSecret: string
    tokenUrl: string
    authorizationUrl: string
    pkceEnabled: string
    pkceMethod: string
    entityId: string
}

export interface IdentityProvider {
    alias: string
    displayName: string
    providerId: string
    discoveryEndpoint: string
    config: Config
}

export const createIdentityProvider = async (
    identityProvider: IdentityProvider
): Promise<IdentityProvider> => {
    return apiRequest(`/IdentityProviders`, { method: 'POST', body: identityProvider })
}

export const getIdentityProvider = async (): Promise<IdentityProvider> => {
    return apiRequest('/IdentityProviders')
}

export const updateIdentityProvider = async (
    alias: string,
    identityProvider: IdentityProvider
): Promise<void> => {
    return apiRequest(`/IdentityProviders/${alias}`, { method: 'PUT', body: identityProvider })
}

export const deleteIdentityProvider = async ({ alias }: { alias: string }): Promise<void> => {
    return apiRequest(`/IdentityProviders/${alias}`, { method: 'DELETE' })
}
