import { type Project, getAllProjects } from '@/api/projects'
import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import AiriaLogo from '@/assets/airia-logo.svg?react'
import Database from '@/assets/icons/database.svg?react'
import { Input } from '@/components/ui/input.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { HandleAuthenticatedError } from '@/lib/HandleErrors'
import { useTheme } from '@/lib/ThemeProvider'
import { cn } from '@/lib/utils'
import type { routeTree } from '@/routeTree.gen'
import { useTour } from '@reactour/tour'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, type RoutePaths, useNavigate } from '@tanstack/react-router'
import { type LucideIcon, PlusIcon, Settings } from 'lucide-react'
import type React from 'react'
import { type ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { CreateNewProject } from './administration/Projects'
import { Separator } from './ui/separator'
import DefaultProjectIcon from '@/assets/icons/default-project-icon.svg?react'
import useTenantQuery from '@/hooks/use-tenant-query.ts'
import { LOW_BALANCE_THRESHOLD } from '@/components/billing/accountSummary.tsx'
import { Plus } from '@untitled-ui/icons-react'
import Container from '@/assets/icons/container.svg?react'
import Activity from '@/assets/icons/activity.svg?react'

const SideNavItem: React.FC<{
    icon?: ReactElement
    title: string
    path?: RoutePaths<typeof routeTree>
    projectId?: string
    onClick?: () => void
    endAdornment?: ReactElement
    className?: string
}> = ({ icon, title, path, projectId, onClick, endAdornment, className }) => {
    if (path) {
        return (
            <Link
                to={path}
                params={{ projectId }}
                className={cn(
                    'flex rounded-[8px] items-center text-foreground hover:bg-background transition-colors p-1 h-8',
                    className
                )}
                activeProps={{
                    className: path ? 'text-primary bg-background hover:bg-accent' : '',
                }}
                activeOptions={{
                    exact: false,
                }}
                onClick={() => onClick?.()}
            >
                <div className="w-full flex items-center gap-4">
                    {icon && <div className="flex items-center justify-center">{icon}</div>}
                    <div className="flex-grow min-w-0">
                        <h3 className="truncate" title={title}>
                            {title}
                        </h3>
                    </div>
                    {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
                </div>
            </Link>
        )
    }
    return (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
            className={cn(
                'w-full flex gap-4 rounded-lg items-center text-foreground hover:bg-background transition-colors p-2',
                className
            )}
            onClick={() => onClick?.()}
        >
            {icon && (
                <div className="flex-shrink-0 w-6 flex items-center justify-center">{icon}</div>
            )}{' '}
            <div className="flex-grow min-w-0 flex items-center justify-between">
                <h3 className="truncate cursor-pointer" title={title}>
                    {title}
                </h3>
            </div>
            {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
        </div>
    )
}

const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

interface ProjectWithRecent extends Project {
    isRecent: boolean
}

export const SideNav = () => {
    const { t } = useTranslation()
    const { theme } = useTheme()
    const { details, isLoading } = useTenantQuery()
    const [_balanceLow, setBalanceLow] = useState(false)
    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: async () => await getAllProjects().catch((e) => HandleAuthenticatedError(e)),
    })
    const [recentProjectIds, setRecentProjectIds] = useState<string[]>([])
    const [searchTerm, setSearchTerm] = useState('')

    const [openProjectSelector, setOpenProjectSelector] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { setCurrentStep } = useTour()

    useEffect(() => {
        const storedRecentProjectIds = localStorage.getItem(RECENT_PROJECT_IDS_KEY)
        if (AllProjectsQuery.isSuccess && AllProjectsQuery.data) {
            if (storedRecentProjectIds) {
                setRecentProjectIds(JSON.parse(storedRecentProjectIds))
            } else {
                const initialRecentProjectIds = AllProjectsQuery.data.slice(0, 5).map((p) => p.id)
                setRecentProjectIds(initialRecentProjectIds)
                localStorage.setItem(
                    RECENT_PROJECT_IDS_KEY,
                    JSON.stringify(initialRecentProjectIds)
                )
            }
            if (localStorage.getItem(LAST_ACCESSED_PROJECT_KEY) === null) {
                localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, AllProjectsQuery.data[0].id)
            }
        }
    }, [AllProjectsQuery.isSuccess, AllProjectsQuery.data, recentProjectIds.length])

    useEffect(() => {
        if (!isLoading && details?.balance !== undefined) {
            setBalanceLow(details.balance <= LOW_BALANCE_THRESHOLD)
        }
    }, [details, isLoading])

    const updateRecentProjects = (selectedProjectId: string) => {
        setRecentProjectIds((prev) => {
            const updatedRecent = [
                selectedProjectId,
                ...prev.filter((id) => id !== selectedProjectId),
            ].slice(0, 5)

            localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
            return updatedRecent
        })

        localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
    }

    const sortedProjects = useMemo(() => {
        if (!AllProjectsQuery.data) return []

        const recentProjectSet = new Set(recentProjectIds)

        const projectsWithRecent: ProjectWithRecent[] = AllProjectsQuery.data.map((project) => ({
            ...project,
            isRecent: recentProjectSet.has(project.id),
        }))

        return projectsWithRecent.sort((a, b) => {
            if (a.isRecent !== b.isRecent) {
                return a.isRecent ? -1 : 1
            }
            return recentProjectIds.indexOf(a.id) - recentProjectIds.indexOf(b.id)
        })
    }, [AllProjectsQuery.data, recentProjectIds])

    const visibleProjects = sortedProjects.slice(0, 5)

    const filteredAndSortedProjects = useMemo(() => {
        return sortedProjects.filter((project) =>
            project.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }, [sortedProjects, searchTerm])

    const onProjectSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const selectorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
                setOpenProjectSelector(false)
            }
        }

        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOpenProjectSelector(false)
            }
        }

        if (openProjectSelector) {
            document.addEventListener('mousedown', handleClickOutside)
            document.addEventListener('keydown', handleEscape)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleEscape)
        }
    }, [openProjectSelector])

    return (
        <>
            <aside className="h-full overflow-y-auto z-10 min-w-60 max-w-60 border-r border-muted bg-card">
                <nav className="h-full flex flex-col items-stretch gap py-6 px-4 text-[13px] tracking-[0.26px]">
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors"
                    >
                        {theme === 'dark' ? (
                            <AiriaLogo className="h-9 w-24 self-start" />
                        ) : (
                            <AiriaLogoBlack className="h-9 w-24 self-start" />
                        )}
                    </Link>
                    <div className="flex flex-col">
                        <Separator className="bg-gray-250 my-4" />
                        <SideNavItem
                            path="/"
                            title={t('insights')}
                            icon={<Activity className="w-4 h-4" stroke="currentcolor" />}
                            className="py-1 px-2 "
                        />
                        <Separator className="bg-gray-250 my-4" />
                        <div className="projects-step">
                            <div className="flex flex-col">
                                <div className={'flex justify-between items-center mb-2 py-1'}>
                                    <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                                        {t('side_nav_headers.project')}
                                    </p>
                                    <CreateNewProject
                                        variant={'ghost'}
                                        size="icon"
                                        Icon={Plus as LucideIcon}
                                        onSuccess={() => {
                                            queryClient.invalidateQueries({
                                                queryKey: [QueryKeys.PROJECTS],
                                            })
                                            navigate({ to: '/' })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-1">
                                {visibleProjects.map((project, index) => (
                                    <SideNavItem
                                        key={project.id}
                                        path="/$projectId/"
                                        projectId={project.id}
                                        title={project.name}
                                        className={cn(index === 0 && '')}
                                        onClick={() => {
                                            if (index === 0) {
                                                setTimeout(
                                                    () => setCurrentStep((prev) => prev + 1),
                                                    250
                                                )
                                            }
                                            updateRecentProjects(project.id)
                                            localStorage.setItem(
                                                LAST_ACCESSED_PROJECT_KEY,
                                                project.id
                                            )
                                        }}
                                        icon={
                                            project.projectIcon ? (
                                                <img
                                                    src={project.projectIcon}
                                                    alt={project.name}
                                                    height={17}
                                                    width={17}
                                                    className="m-1"
                                                />
                                            ) : (
                                                <DefaultProjectIcon className="w-[17px] h-[17px] m-1" />
                                            )
                                        }
                                    />
                                ))}
                                {sortedProjects.length > 5 && (
                                    <SideNavItem
                                        title={t('view_all_projects')}
                                        onClick={() => setOpenProjectSelector((prev) => !prev)}
                                        className="text-primary-900"
                                    />
                                )}
                            </div>
                        </div>
                        <Separator className="bg-gray-250 my-4" />
                        <div className="libraries-step">
                            <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal py-1 mb-2">
                                {t('side_nav_headers.libraries')}
                            </p>
                            <div className={'flex flex-col gap-2'}>
                                <SideNavItem
                                    path="/datasources"
                                    title={t('connectors')}
                                    icon={<Database className="w-4 h-4" stroke="currentcolor" />}
                                />
                                <SideNavItem
                                    path="/library"
                                    title={t('models')}
                                    icon={<Container className="w-4 h-4" stroke="currentcolor" />}
                                />
                            </div>
                        </div>
                        <Separator className="bg-gray-250 my-4" />
                        <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal py-1 mb-2">
                            {t('side_nav_headers.feeds')}
                        </p>
                        <div className={'flex flex-col gap-2'}>
                            <SideNavItem
                                path="/feed"
                                title={t('usage')}
                                icon={<Database className="w-4 h-4" stroke="currentcolor" />}
                            />
                            <SideNavItem
                                path="/consumption"
                                title={t('consumption')}
                                icon={<Container className="w-4 h-4" stroke="currentcolor" />}
                            />
                        </div>
                        <Separator className="bg-gray-250 my-4" />
                        <SideNavItem
                            path="/settings"
                            title={t('account')}
                            icon={<Settings className="w-4 h-4" stroke="currentcolor" />}
                        />
                    </div>
                </nav>
            </aside>
            {openProjectSelector && (
                <aside
                    ref={selectorRef}
                    tabIndex={0}
                    className="absolute left-60 h-full overflow-y-auto z-10 min-w-60 max-w-60 border-r border-muted bg-card"
                >
                    <nav className="flex flex-col items-stretch gap-9 p-4 text-[13px] tracking-[0.26px]">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                                    {'ALL PROJECTS'}
                                </p>
                                <CreateNewProject
                                    variant={'ghost'}
                                    size="icon"
                                    Icon={PlusIcon}
                                    onSuccess={() => {
                                        queryClient.invalidateQueries({
                                            queryKey: [QueryKeys.PROJECTS],
                                        })
                                        navigate({ to: '/' })
                                    }}
                                />
                            </div>
                            <Input
                                placeholder={'Search Projects'}
                                onChange={onProjectSearchChange}
                                className={'bg-background'}
                            />
                            {filteredAndSortedProjects.map((project) => (
                                <SideNavItem
                                    key={project.id}
                                    path="/$projectId/pipelines"
                                    projectId={project.id}
                                    title={project.name}
                                    onClick={() => {
                                        setOpenProjectSelector(false)
                                        updateRecentProjects(project.id)
                                    }}
                                    icon={
                                        project.projectIcon ? (
                                            <img
                                                src={project.projectIcon}
                                                alt={project.name}
                                                height={17}
                                                width={17}
                                                className="m-1"
                                            />
                                        ) : (
                                            <DefaultProjectIcon className="w-[17px] h-[17px] m-1" />
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </nav>
                </aside>
            )}
        </>
    )
}
