import { getActiveOrganization } from '@/api/workspaces'
import Container from '@/assets/icons/container.svg?react'
import Dataflow from '@/assets/icons/dataflow.svg?react'
import SIEM from '@/components/settings/siem.tsx'
import SSO from '@/components/settings/sso.tsx'
import { TabTrigger } from '@/components/ui/tab-trigger'
import { TabsContent } from '@/components/ui/tabs.tsx'
import { QueryKeys } from '@/constants/QueryKeys'
import { Tabs, TabsList } from '@radix-ui/react-tabs'
import { useQuery } from '@tanstack/react-query'
import { Link, createFileRoute } from '@tanstack/react-router'
import Group from '@/assets/icons/group.svg?react'
import ProjectIcon from '@/assets/icons/projects.svg?react'
import Projects from '@/components/administration/Projects'
import { useMemo } from 'react'
import Workspaces from '@/components/administration/Workspaces'
import { FeatureFlag, getFeatureFlag } from '@/api/featureFlag'
import Coins from '@/assets/icons/coins.svg?react'
import User from '@/assets/icons/user.svg?react'
import { Key, KeySquare, Lock } from 'lucide-react'

export const Route = createFileRoute('/_mainLayout/settings')({
    component: () => {
        return <Settings key={'settings'} />
    },
})

function Settings() {
    const activeOrganizationQuery = useQuery({
        queryKey: [QueryKeys.ACTIVE_ORGANIZATION],
        queryFn: getActiveOrganization,
    })

    const featureFlagQuery = useQuery({
        queryKey: [FeatureFlag.EnableSsoConfigurationFeatureFlag],
        queryFn: () => getFeatureFlag(FeatureFlag.EnableSsoConfigurationFeatureFlag),
    })

    const otherConfigs = [
        {
            value: 'billing',
            icon: <Coins className="w-6 h-6 mr-2 -ml-1" stroke="currentcolor" />,
            label: 'Billing',
            to: '/billing',
        },
        {
            value: 'apikeys',
            icon: <Key className="w-6 h-6 mr-2 -ml-1" stroke="currentcolor" />,
            label: 'API Keys',
            to: '/apikeys',
        },
        {
            value: 'credentials',
            icon: <Lock className="w-6 h-6 mr-2 -ml-1" stroke="currentcolor" />,
            label: 'Credentials',
            to: '/credentials',
        },
        {
            value: 'gateway',
            icon: <KeySquare className="w-6 h-6 mr-2 -ml-1" stroke="currentcolor" />,
            label: 'AI Gateway',
            to: '/aigateway',
        },
        {
            value: 'users',
            icon: <User className="w-6 h-6 mr-2 -ml-1" stroke="currentcolor" />,
            label: 'Users',
            to: '/administration/users',
        },
    ]

    const tabConfigs = useMemo(() => {
        let baseConfigs = [
            { value: 'siem', icon: <Dataflow className="w-6 h-6 mr-2 -ml-1" />, label: 'SIEM' },
            {
                value: 'projects',
                icon: <ProjectIcon className="w-6 h-6 mr-2 -ml-1" />,
                label: 'Projects',
            },
        ]

        if (featureFlagQuery.data) {
            const ssoConfig = {
                value: 'sso',
                icon: <Container className="w-6 h-6 mr-2 -ml-1" />,
                label: 'SSO',
            }
            baseConfigs = [baseConfigs[0], ssoConfig, ...baseConfigs.slice(1)]
        }
        if (
            activeOrganizationQuery.isSuccess &&
            activeOrganizationQuery.data.createTenantsEnabled
        ) {
            baseConfigs.push({
                value: 'workspaces',
                icon: <Group className="w-6 h-6 mr-2 -ml-1" />,
                label: 'Workspaces',
            })
        }

        return baseConfigs
    }, [
        activeOrganizationQuery.isSuccess,
        activeOrganizationQuery.data?.createTenantsEnabled,
        featureFlagQuery.data,
    ])

    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs className="flex flex-col" defaultValue="siem">
                <TabsList className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    {tabConfigs.map((config) => (
                        <TabTrigger key={config.value} {...config} />
                    ))}
                    {otherConfigs.map((config) => {
                        return (
                            <Link key={config.value} to={config.to}>
                                <TabTrigger key={config.value} {...config} />
                            </Link>
                        )
                    })}
                </TabsList>
                <TabsContent value={'siem'}>
                    <SIEM />
                </TabsContent>
                <TabsContent value={'sso'}>
                    <SSO />
                </TabsContent>
                <TabsContent value={'workspaces'}>
                    <Workspaces />
                </TabsContent>
                <TabsContent value={'projects'}>
                    <Projects />
                </TabsContent>
            </Tabs>
        </div>
    )
}
