import apiRequest from '@/lib/apiClient'
export type Tenant = {
    id: string
    name: string
    identifier: string
    createdAt: string
    accessControlGroupId: string
}

export type Organization = {
    id: string
    externalId: string
    name: string
    displayName: string
    identifier: string
    createTenantsEnabled: boolean
    createdAt: string
    updatedAt: string
    tenants: Tenant[]
}

export const updateTenant = async (
    tenantId: string,
    createAccessControlGroup: boolean
): Promise<boolean> => {
    return apiRequest(`/tenants/${tenantId}`, {
        method: 'PATCH',
        body: { createAccessControlGroup },
    })
}

export const createAdditionalTenant = async (
    name: string,
    createAccessControlGroup: boolean
): Promise<boolean> => {
    return apiRequest('/tenants/additional', {
        method: 'POST',
        body: { name, createAccessControlGroup },
    })
}

export const setTenant = async (
    organizationExternalId: string,
    tenantId: string
): Promise<boolean> => {
    return apiRequest('/tenants', { method: 'PUT', body: { organizationExternalId, tenantId } })
}

export const getActiveTenant = async (): Promise<Tenant> => {
    return apiRequest('/tenants')
}

export const getTenantById = async (tenantId: string): Promise<Tenant> => {
    return apiRequest(`/tenants/${tenantId}`)
}

export const getOrganizations = async (): Promise<Organization[]> => {
    return apiRequest('/organizations')
}

export const getActiveOrganization = async (): Promise<Organization> => {
    return apiRequest('/organizations/active')
}
