import { type FeedResult, getFeedDetails } from '@/api/feed'
import { getAllProjects } from '@/api/projects'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import ArrowCircleUp from '@/assets/icons/arrow-circle-up.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn, downloadFile } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { themes } from 'prism-react-renderer'
import type React from 'react'
import { useEffect, useRef } from 'react'
import { CodeBlock } from 'react-code-block'
import { getPrettyTag } from './FeedTable'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import { toast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { t } from 'i18next'
import { Clipboard, Download } from 'lucide-react'

interface FeedModalProps {
    isOpen: boolean
    onClose: () => void
    callDetails: FeedResult
    projectId?: string
}

const ExecutionModal: React.FC<FeedModalProps> = ({ isOpen, onClose, callDetails, projectId }) => {
    const chatEndRef = useRef<HTMLDivElement>(null)

    const allProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const feedDetailsQuery = useQuery({
        queryKey: ['feed-execution-details', callDetails.id],
        queryFn: () => getFeedDetails(callDetails.id),
    })

    const date = new Date(callDetails.createdAt)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setTimeout(() => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: 'instant' })
            }
        }, 50)
    }, [isOpen])

    const renderDuration = (callDuration: string) => {
        const parts = callDuration.split(':')
        const hours = Number.parseInt(parts[0], 10)
        const minutes = Number.parseInt(parts[1], 10)
        const seconds = Number.parseFloat(parts[2])
        const totalSeconds = hours * 3600 + minutes * 60 + seconds
        const duration = `${totalSeconds.toFixed(2)} seconds`

        return <p className="text-[15px] text-gray-550">{duration}</p>
    }

    const renderSelectedProject = (projectId: string | undefined) => {
        const selectedProject = allProjectsQuery.data?.find((project) => project.id === projectId)
        return selectedProject ? (
            <div className={'flex gap-6 items-center'}>
                {selectedProject.projectIcon ? (
                    <img
                        alt="project"
                        src={selectedProject.projectIcon}
                        className={'h-12 w-12 mr-1'}
                    />
                ) : (
                    <Airia className={'h-12 w-12'} />
                )}
                <p className="font-gilroy-medium text-4xl text-gray-550">{selectedProject.name}</p>
            </div>
        ) : (
            <div className={'flex gap-4 items-center'}>
                {callDetails.source === 'Pipeline' && (
                    <DataFlow className="w-12 h-12" stroke="currentColor" />
                )}
                {callDetails.source === 'Playground' && (
                    <ArrowCircleUp className="w-12 h-12" stroke="currentColor" />
                )}
                <p className="font-gilroy-medium text-4xl text-gray-550">
                    {callDetails.pipelineExecutionName}
                </p>
            </div>
        )
    }

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(JSON.stringify(feedDetailsQuery.data, null, 4))
        toast({
            title: 'Copy to clipboard',
            description: 'Report copied to clipboard',
        })
    }

    const download = () => {
        downloadFile(
            JSON.stringify(feedDetailsQuery.data, null, 4),
            `${callDetails.pipelineExecutionName}-${date.toISOString()}.json`,
            'text/json'
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-modal-lg max-h-[1000px]">
                <DialogHeader>
                    <DialogTitle>Execution details</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between">
                        {renderSelectedProject(projectId)}
                        <div className="text-sm text-gray-550 text-right">
                            <div>Prompt Tokens: {callDetails.promptTokens}</div>
                            <div>Completion Tokens: {callDetails.completionTokens}</div>
                        </div>
                    </div>

                    <table className="w-full text-sm">
                        <thead>
                            <tr className="text-left text-gray-500">
                                <th>Timestamp</th>
                                <th>Model</th>
                                <th>Source</th>
                                <th>API Key</th>
                                <th>Response Time</th>
                                <th>Total Tokens</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                                    </div>
                                </td>
                                <td className="py-2">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col gap-[3px]">
                                            <div className="flex flex-row items-center">
                                                <ProviderIcon
                                                    provider={callDetails.providerType}
                                                    className="w-6 h-6 mr-2 -ml-2"
                                                />
                                                <p className="text-gray-550">
                                                    {callDetails.providerType}
                                                </p>
                                            </div>
                                            <p className="text-[15px] text-gray-450 ml-6 max-w-32 text-ellipsis line-clamp-1">
                                                {callDetails.requestModel}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col text-gray-550">
                                        <div className="flex flex-row gap-2 items-center">
                                            {callDetails.source === 'Pipeline' && (
                                                <DataFlow
                                                    className="w-4 h-4"
                                                    stroke="currentColor"
                                                />
                                            )}
                                            {callDetails.source === 'Playground' && (
                                                <ArrowCircleUp
                                                    className="w-4 h-4"
                                                    stroke="currentColor"
                                                />
                                            )}
                                            <p className="text-gray-550">{callDetails.source}</p>
                                        </div>
                                        {callDetails.source === 'Pipeline' && (
                                            <p className="text-gray-450 ml-[25px]">{`${callDetails.pipelineExecutionName} - v${callDetails.pipelineVersionNumber}`}</p>
                                        )}
                                        {callDetails.source === 'Playground' &&
                                            callDetails.pipelineExecutionName && (
                                                <p className="text-gray-450 ml-[25px]">
                                                    {callDetails.pipelineExecutionName}
                                                </p>
                                            )}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p className="text-gray-550">
                                            {callDetails.apiKeyName !== null &&
                                            callDetails.apiKeyName !== ''
                                                ? callDetails.apiKeyName
                                                : 'AiriaKey'}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col text-gray-550">
                                        {renderDuration(callDetails.duration)}
                                    </div>
                                </td>
                                <td>
                                    <p className="text-[15px] text-gray-550">
                                        {callDetails.totalTokens} tokens
                                    </p>
                                </td>
                                <td>
                                    {callDetails?.tags && (
                                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                                            {callDetails.tags.map((rawTag) => {
                                                const tag = getPrettyTag(rawTag)
                                                if (
                                                    tag !== 'MemoryLoadStep' &&
                                                    tag !== 'MemoryStoreStep'
                                                )
                                                    return (
                                                        <Badge
                                                            key={tag}
                                                            className={cn(
                                                                'rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 hover:text-gray-550 justify-center',
                                                                tag === 'Streamed' &&
                                                                    'bg-primary-100 hover:bg-primary-100',
                                                                tag === 'Tools' &&
                                                                    'bg-secondary-muted hover:bg-secondary-muted',
                                                                tag === 'RAG' &&
                                                                    'bg-error-100 hover:bg-error-100',
                                                                tag === 'Python' &&
                                                                    'bg-secondary-100 hover:bg-secondary-100'
                                                            )}
                                                        >
                                                            {tag}
                                                        </Badge>
                                                    )
                                            })}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <h3 className="font-semibold mb-2">Pipeline Result</h3>
                        <div className="space-y-4 h-[400px] overflow-y-auto px-4">
                            {feedDetailsQuery.data && (
                                <div className={cn('p-4 rounded-lg bg-blue-50 h-full')}>
                                    <CodeBlock
                                        language="js"
                                        code={JSON.stringify(feedDetailsQuery.data, null, 4)}
                                        theme={themes.vsLight}
                                    >
                                        <CodeBlock.Code
                                            as="div"
                                            className="mt-1 p-2 h-full rounded-xl text-wrap whitespace-pre-wrap break-all overflow-y-scroll bg-transparent"
                                        >
                                            <div className="table-row overflow-auto">
                                                <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                                                <CodeBlock.LineContent className="table-cell text-sm">
                                                    <CodeBlock.Token />
                                                </CodeBlock.LineContent>
                                            </div>
                                        </CodeBlock.Code>
                                    </CodeBlock>
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-row justify-end gap-1 mt-3 -mb-3">
                            <Button
                                type="button"
                                variant="secondary"
                                className="text-primary border-primary flex justify-between gap-1"
                                onClick={copyToClipboard}
                            >
                                {t('copy_text')}
                                <Clipboard className="w-4" />
                            </Button>
                            <Button
                                type="button"
                                className="flex justify-between gap-1"
                                onClick={download}
                            >
                                {t('download_json')}
                                <Download className="w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ExecutionModal
