import apiRequest from '@/lib/apiClient'

export const uploadImage = async ({
    file,
}: {
    file: File
}): Promise<{ imageUrl: string; id: string }> => {
    const formData = new FormData()
    formData.append('file', file)

    return apiRequest('/upload', {
        method: 'POST',
        body: formData,
        isFormData: true,
    })
}
