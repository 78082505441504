import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { FeedTable } from '@/components/home/feed/FeedTable.tsx'

export const Route = createFileRoute('/_mainLayout/_homeLayout/feed')({
    component: Feed,
    // //@ts-ignore
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function Feed() {
    const queryParams = Route.useSearch()
    const params = Route.useParams()
    return <FeedTable queryParams={queryParams} params={params} />
}
