import Azure from '@/assets/azure.svg'
import Box from '@/assets/box.svg'
import Confluence from '@/assets/confluence.svg'
import Cassandra from '@/assets/connectors/cassandra.png'
import Cloudkit from '@/assets/connectors/cloukit-logo.png'
import DataDog from '@/assets/connectors/datadog.svg'
import Dropbox from '@/assets/connectors/dropbox_symbol.svg'
import FileUpload from '@/assets/connectors/file-upload.svg'
import Git from '@/assets/connectors/git.jpg'
import GitHub from '@/assets/connectors/github.svg'
import GoogleDrive from '@/assets/connectors/googledrive.png'
import MongoDB from '@/assets/connectors/mongodb.svg'
import Notion from '@/assets/connectors/notion.svg'
import OneDrive from '@/assets/connectors/onedrive_logo.svg'
import PubMed from '@/assets/connectors/pubmed.png'
import SharePoint from '@/assets/connectors/sharepoint_logo.svg'
import Slack from '@/assets/connectors/slack.svg'
import SnowFlake from '@/assets/connectors/snowflake.svg'
import Telegram from '@/assets/connectors/telegram.png'
import Trello from '@/assets/connectors/trello.svg'
import Unsplash from '@/assets/connectors/unsplash_symbol.svg'
import Wikipedia from '@/assets/connectors/wikipedia.png'
import S3 from '@/assets/s3.svg'
import Scaleflex from '@/assets/scaleflex.svg'
import { DataSourceFormBuilder } from '@/components/newDataSource/dataSourceFormBuilder'
import { FileUp, LucideIcon } from 'lucide-react'

export interface Connector {
    id: string
    name: string
    icon?: string
    iconComponent?: LucideIcon
    description: string
    classification: string
}

interface ConnectorInfo {
    icon: string | LucideIcon
    description: string
    classification: ConnectorCategory
}
enum ConnectorCategory {
    DatabaseAndAnalytics = 'Database and Analytics',
    FileManagementAndStorage = 'File Management & Storage',
    CommunicationAndCollaboration = 'Communication and Collaboration',
    ITAndInfrastructure = 'IT and Infrastructure',
    DeveloperTools = 'Developer Tools',
    Healthcare = 'Healthcare',
    Productivity = 'Productivity',
    SocialNetworks = 'Social Networks',
    WebContent = 'Web Content',
    Images = 'Images',
    Other = 'Other',
}

export const connectorMapping: { [key: string]: ConnectorInfo } = {
    AzureBlob: {
        icon: Azure,
        description:
            'Azure Blob Storage is a cloud object storage service by Microsoft that helps you store any amount of data for a range of use cases like data lakes, backups, applications, etc.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    ConfluenceLoader: {
        icon: Confluence,
        description:
            'Confluence is a collaboration tool that companies can use to create and collaborate on documents like new concepts, project updates and documentation, and so on.',
        classification: ConnectorCategory.CommunicationAndCollaboration,
    },
    ScaleFlex: {
        icon: Scaleflex,
        description:
            'Scaleflex is a cloud service that helps you manage all media assets like images and web files. With this connector, you can import your Scaleflex images.',
        classification: ConnectorCategory.Images,
    },
    CloudKit: {
        icon: Cloudkit,
        description:
            'This connector helps you import your Apple CloudKit data into your workspace.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    SharePointLoader: {
        icon: SharePoint,
        description:
            'SharePoint is a browser-based app that you connect to through your web browser.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    OneDrive: {
        icon: OneDrive,
        description:
            'OneDrive is a file hosting service and synchronization service operated by Microsoft as part of its web version of Office.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    DropboxLoader: {
        icon: Dropbox,
        description:
            'Seamlessly integrate with the tools you use to enhance automation, streamline workflows, and unlock advanced data insights for your business.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    UnsplashLoader: {
        icon: Unsplash,
        description: 'Import your Unsplash images into your workspace.',
        classification: ConnectorCategory.Images,
    },
    FileUpload: {
        icon: FileUpload,
        description: 'You can manually upload your files to your workspace.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    Box: {
        icon: Box,
        description: 'Add data from box.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    S3: {
        icon: S3,
        description:
            'Amazon S3 is an object storage service by AWS that allows you to store any amount of data for a range of use cases like data lakes, backups, applications, etc.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    Airtable: {
        icon: FileUp,
        description:
            'Airtable is a no-code tool that lets you create applications with a spreadsheet-like interface for a variety of use cases.',
        classification: ConnectorCategory.DatabaseAndAnalytics,
    },
    Cassandra: {
        icon: Cassandra,
        description: 'Cassandra is an open-source NoSQL distributed database.',
        classification: ConnectorCategory.DatabaseAndAnalytics,
    },
    DatadogLogsLoader: {
        icon: DataDog,
        description:
            'Datadog is a monitoring and analytics tool for DevOps teams that is used to determine performance metrics as well as Event monitoring for infrastructure and cloud services. With the connector, you can import the Datadog logs into your workspace.',
        classification: ConnectorCategory.ITAndInfrastructure,
    },
    GitLoader: {
        icon: Git,
        description:
            'Git is a distributed version control system that tracks versions of files. It is used to manage the source code while you are collaboratively developing software.',
        classification: ConnectorCategory.DeveloperTools,
    },
    GitHubIssuesLoader: {
        icon: GitHub,
        description:
            'GitHub is a cloud-based platform where you can store, share, and work together with others to write code.',
        classification: ConnectorCategory.DeveloperTools,
    },
    GithubFileLoader: {
        icon: GitHub,
        description: 'Import individual files from GitHub.',
        classification: ConnectorCategory.DeveloperTools,
    },
    GoogleDriveLoader: {
        icon: GoogleDrive,
        description:
            'Google Drive is a cloud-based file storage service that enables end users to store and access files online. The service syncs the stored files across multiple connected devices.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    GoogleDrive: {
        icon: GoogleDrive,
        description:
            'Google Drive is a cloud-based file storage service that enables end users to store and access files online. The service syncs the stored files across multiple connected devices.',
        classification: ConnectorCategory.FileManagementAndStorage,
    },
    MongodbLoader: {
        icon: MongoDB,
        description:
            'MongoDB is a NoSQL document database that provides support for JSON-like storage, it offers a flexible data model that enables you to store unstructured data.',
        classification: ConnectorCategory.DatabaseAndAnalytics,
    },
    NotionDBLoader: {
        icon: Notion,
        description:
            "Notion is a collaboration tool that blends your everyday work apps into one. It's the all-in-one workspace for you and your team.",
        classification: ConnectorCategory.CommunicationAndCollaboration,
    },
    PubMedLoader: {
        icon: PubMed,
        description:
            'The PubMed database contains more than 37 million citations and abstracts of biomedical and life sciences literature, it allows search but does not include full-text journal articles.',
        classification: ConnectorCategory.Healthcare,
    },
    SnowflakeLoader: {
        icon: SnowFlake,
        description:
            'Snowflake is a cloud data platform that offers data to be stored, processed, and made available to meet a variety of analytical needs for companies.',
        classification: ConnectorCategory.DatabaseAndAnalytics,
    },
    Slack: {
        icon: Slack,
        description:
            'Slack is a cloud-based team communication platform, communication is organised in channels and the platform offers multiple automated workflows.',
        classification: ConnectorCategory.CommunicationAndCollaboration,
    },
    TelegramChatFileLoader: {
        icon: Telegram,
        description:
            'Telegram is a cloud-based mobile and desktop messaging app with a focus on security.',
        classification: ConnectorCategory.CommunicationAndCollaboration,
    },
    TelegramChatApiLoader: {
        icon: Telegram,
        description: 'Use the Telegram API to load data.',
        classification: ConnectorCategory.CommunicationAndCollaboration,
    },
    TrelloLoader: {
        icon: Trello,
        description:
            'Trello is a team collaboration tool that helps you organize and project manage your work.',
        classification: ConnectorCategory.Productivity,
    },
    Twitter: {
        icon: FileUp,
        description:
            'Twitter / X is a social networking service that allows users to exchange ideas via sharing short text messages.',
        classification: ConnectorCategory.SocialNetworks,
    },
    UnstructuredURLLoader: {
        icon: FileUp,
        description:
            'URL loader will allow you to retrieve web content from specified web addresses (URLs).',
        classification: ConnectorCategory.WebContent,
    },
    WikipediaLoader: {
        icon: Wikipedia,
        description:
            'Wikipedia is a free content web encyclopedia written and maintained by a community of volunteers on a variety of topics.',
        classification: ConnectorCategory.WebContent,
    },
}

export const formatDisplayName = (type: string): string => {
    let displayName = type.endsWith('Loader') ? type.slice(0, -6) : type
    switch (displayName) {
        case 'AzureBlob':
            return 'Azure Blob Storage'
        case 'CloudKit':
            return 'Apple CloudKit'
        case 'DropBox':
            return 'Dropbox'
        case 'GitHubIssues':
            return 'GitHub'
        case 'Mongodb':
            return 'MongoDB'
        case 'NotionDB':
            return 'Notion'
        case 'PubMed':
            return 'PubMed'
        case 'S3':
            return 'Amazon S3'
        case 'ScaleFlex':
            return 'Scaleflex'
        case 'TelegramChatAPI':
            return 'Telegram Chat API'
        default:
            displayName = displayName
                .replace(/([A-Z])([A-Z][a-z])|([a-z])([A-Z])/g, '$1$3 $2$4')
                .trim()
            return displayName.charAt(0).toUpperCase() + displayName.slice(1)
    }
}

export const mapFormsToConnectors = (forms: DataSourceFormBuilder): Connector[] => {
    return Object.keys(forms)
        .map((type) => {
            const displayName = formatDisplayName(type)
            const connectorInfo = connectorMapping[type] || {
                icon: FileUp,
                description: `Import your ${displayName} data into your workspace.`,
                classification: ConnectorCategory.Other,
            }
            const connector: Connector = {
                id: type,
                name: displayName,
                description: connectorInfo.description,
                classification: connectorInfo.classification,
            }
            // Handle both string and LucideIcon types
            if (typeof connectorInfo.icon === 'string') {
                connector.icon = connectorInfo.icon
            } else {
                connector.iconComponent = connectorInfo.icon
            }
            return connector
        })
        .sort((a, b) => a.name.localeCompare(b.name))
}

interface ConnectorIconProps {
    connector: string
    className?: string
}

type IconType = React.ComponentType<React.SVGProps<SVGSVGElement>> | string

const IconComponent: React.FC<{ icon: IconType; className?: string }> = ({ icon, className }) => {
    if (typeof icon === 'string') {
        return <img src={icon} alt="" className={className} />
    }
    const Icon = icon
    return <Icon className={className} />
}

export const ConnectorIcon: React.FC<ConnectorIconProps> = ({ connector, className }) => {
    const getIcon = (icon: IconType): JSX.Element => (
        <IconComponent icon={icon} className={className} />
    )

    const iconMap: { [key: string]: IconType } = {
        onedrive: OneDrive,
        sharepoint: SharePoint,
        dropbox: Dropbox,
        cloudkit: Cloudkit,
        unsplash: Unsplash,
        scaleflex: Scaleflex,
        azure: Azure,
        confluence: Confluence,
        box: Box,
        s3: S3,
        datadog: DataDog,
        github: GitHub,
        googledrive: GoogleDrive,
        notion: Notion,
        pubmed: PubMed,
        snowflake: SnowFlake,
        git: Git,
        mongodb: MongoDB,
        slack: Slack,
        telegram: Telegram,
        cassandra: Cassandra,
        trello: Trello,
        wikipedia: Wikipedia,
    }

    const connectorLower = connector.toLowerCase()

    for (const [key, icon] of Object.entries(iconMap)) {
        if (connectorLower.includes(key)) {
            return getIcon(icon)
        }
    }

    return <FileUp className={className} />
}
