import { Group, createGroup, getAllGroups } from '@/api/groups.ts'
import { User, getAllInternalUsers } from '@/api/users.ts'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader.tsx'
import { Button } from '@/components/ui/button'
import { CardContent, CardHeader } from '@/components/ui/card'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input.tsx'
import { Label } from '@/components/ui/label.tsx'
import { Toaster } from '@/components/ui/toaster.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination.ts'
import useSearchEffect from '@/hooks/use-search-effect.ts'
import { useSorting } from '@/hooks/use-sorting.ts'
import { handleError } from '@/lib/HandleErrors'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, SearchSchemaInput, createFileRoute, useNavigate } from '@tanstack/react-router'
import { ColumnDef, flexRender } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { cx } from 'class-variance-authority'
import { format } from 'date-fns/format'
import { LucideIcon, MoreHorizontal, Pencil, Search } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { DefaultDataTable } from '@/components/DefaultDataTable'

interface GroupsProps {
    id: string
    name: string
    users: User[]
    createdAt: string
    parentId?: string
    tenantId?: string
    identityServerGroupId: string
}

export const Route = createFileRoute('/_mainLayout/_administrationLayout/administration/groups')({
    component: () => {
        return <Groups />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search?.pageNumber as number,
            pageSize: search?.pageSize as number,
            sortBy: search?.sortBy as string,
        }
    },
})

const getRandomColor = () => {
    return [
        'bg-green-500',
        'bg-amber-500',
        'bg-red-300',
        'bg-purple-300',
        'bg-blue-300',
        'bg-orange-300',
        'bg-yellow-500',
        'bg-cyan-300',
        'bg-pink-300',
        'bg-teal-300',
        'bg-lime-500',
        'bg-indigo-300',
        'bg-violet-300',
        'bg-fuchsia-300',
        'bg-rose-300',
    ][Math.floor(Math.random() * 15)]
}

const Groups: React.FC = () => {
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const AllGroupsQuery = useQuery({
        queryKey: [QueryKeys.GROUPS, queryParams],
        queryFn: async () =>
            await getAllGroups(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const handleRowClick = (row: { original: Group }) => {
        navigate({
            to: '/groups/$id/edit',
            params: {
                id: row.original.id,
            },
        })
    }
    const columns: ColumnDef<Group>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'createdAt',
            header: t('created_date'),
            cell: (info: any) =>
                flexRender(
                    ({ createdAt }: Group) => format(new Date(createdAt), 'MM/dd/yyyy'),
                    info.row.original
                ),
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 "> Actions</DropdownMenuLabel>
                            <DropdownMenuItem>
                                <Link
                                    to="/groups/$id/edit"
                                    params={{
                                        id: row.original.id,
                                    }}
                                    className="flex gap-1"
                                >
                                    <Pencil className="h-4 w-4" />
                                    {t('users_models.editGroup')}
                                </Link>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]
    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy:
                    //@ts-ignore
                    columns.find((col) => col.accessorKey === field) != null ? field : 'createdAt',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState])

    return (
        <>
            <div className="h-[80vh] mr-3 bg-background">
                <CardHeader>
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col gap-2 grow">
                            <PaginationHeader
                                queryParams={queryParams}
                                filter={searchValue}
                                setFilter={setSearchValue}
                                sortOptions={columns}
                            />
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <DefaultDataTable
                        className="h-[70vh]"
                        data={AllGroupsQuery.data?.items ?? []}
                        columns={columns}
                        serverPagination
                        totalCount={AllGroupsQuery.data?.totalCount}
                        onRowClick={handleRowClick}
                        limit={limit}
                        onPaginationChange={onPaginationChange}
                        onSortingChange={onSortingChange}
                        paginationState={paginationState}
                        sortingState={sortingState}
                    />
                </CardContent>
            </div>
            <Toaster />
        </>
    )
}

export const CreateGroup = ({
    variant,
    onSuccess,
    size,
    Icon,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon'
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const [openCreate, setOpenCreate] = useState(false)
    const appForm = useForm<GroupsProps>()
    const queryClient = useQueryClient()
    const options = {
        page: 1,
        pageSize: 10,
        filter: '',
    }
    const [userQuery, setUserQuery] = useState(options)
    const GetAllUsersQuery = useQuery({
        queryKey: [QueryKeys.ALL_USERS, userQuery],
        queryFn: () => getAllInternalUsers(userQuery ?? { page: 1, pageSize: 10 }),
    })

    const [suggestedUsers, setSuggestedUsers] = useState([] as User[])
    const [selectedUsers, setSelectedUsers] = useState([] as User[])
    const handleAddUser = (user: User) => {
        setSelectedUsers([...selectedUsers, user])
    }

    const handleRemoveUser = (user: User) => {
        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
        user.isSelected = false
        setSuggestedUsers([...suggestedUsers, user])
    }

    const handleCreateGroup = async (data: GroupsProps) => {
        try {
            // @ts-ignore
            await createGroup({
                ...data,
                users: selectedUsers,
            }).catch((error) => {
                console.log(error)
                toast({
                    title: 'Error',
                    description: error.message,
                    variant: 'destructive',
                })
            })
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            setOpenCreate(false)
            onSuccess && onSuccess()
            GetAllUsersQuery.refetch()
        } catch (error) {
            console.error(error)
            handleError(error)
        }
    }

    const limit = 12
    const displayedUsers = selectedUsers.slice(0, limit)

    const handleSearch = (searchText: string) => {
        setSearchTerm(searchText)
    }

    const isUserSelected = (user: User) => {
        return selectedUsers.some((selectedUser) => selectedUser.id === user.id)
    }

    useEffect(() => {
        const query = { ...userQuery, filter: debouncedSearchTerm }
        setUserQuery(query)
        GetAllUsersQuery.refetch()
    }, [debouncedSearchTerm])
    return (
        <>
            <Button type="button" size={size} onClick={() => setOpenCreate(true)} variant={variant}>
                {Icon ? <Icon className="text-muted-foreground h-5 w-5" /> : t('createGroup')}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate} aria-label="Create Group">
                <form onSubmit={appForm.handleSubmit(handleCreateGroup)}>
                    <DialogContent className={'max-w-modal-lg max-h-modal px-0'}>
                        <DialogHeader className="-mt-3 mb-2 px-5">{t('createGroup')}</DialogHeader>
                        <div className={'overflow-auto max-h-[625px] px-5'}>
                            <div className={'grid grid-cols-4'}>
                                <div></div>
                                <div className={'col-span-4'}>
                                    <div className={'flex flex-col gap-4'}>
                                        <div>
                                            <Label htmlFor="groupName" className="text-left">
                                                {t('groupName')}
                                            </Label>
                                            <Input
                                                id="name"
                                                type="text"
                                                className="col-span-3"
                                                {...appForm.register('name', { required: true })}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="addUser" className="text-left">
                                                {t('addUsers')}
                                            </Label>
                                            <Input
                                                startIcon={Search}
                                                value={searchTerm}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                className={cx('border-none, outline-none, flex-1')}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="suggestedUsers" className="text-left">
                                                {t('suggestedUsers')}
                                            </Label>
                                            <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                                {GetAllUsersQuery.data?.items.map((user) => (
                                                    <li
                                                        key={user.id}
                                                        className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                                    >
                                                        <div className="ml-2">
                                                            <span
                                                                className={cx(
                                                                    getRandomColor(),
                                                                    'text-white text-md px-2 py-1 rounded-full justify-start'
                                                                )}
                                                            >
                                                                {user.firstName[0]}
                                                                {user.lastName[0]}
                                                            </span>
                                                        </div>
                                                        <h6 className="flex-1 text-sm text-slate-600">
                                                            {user.firstName} {user.lastName}
                                                        </h6>

                                                        <button
                                                            type="button"
                                                            className="group block h-full w-16 hover:bg-slate-400"
                                                            onClick={() =>
                                                                isUserSelected(user)
                                                                    ? handleRemoveUser(user)
                                                                    : handleAddUser(user)
                                                            }
                                                        >
                                                            <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                                {isUserSelected(user) ? '✔' : '+'}
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <Label htmlFor="selectedUsers" className="text-left">
                                                {t('selectedUsers')}
                                            </Label>
                                            <ul className="overflow-auto h-20 flex flex-wrap">
                                                {displayedUsers.map((user) => (
                                                    <li key={user.id} className="flex items-center">
                                                        <div className="p-2">
                                                            <Button
                                                                className={cx(
                                                                    getRandomColor(),
                                                                    'group inline-block rounded-full p-4 hover:bg-slate-200 hover:p-2 h-12 w-12 text-xs'
                                                                )}
                                                                onClick={() =>
                                                                    handleRemoveUser(user)
                                                                }
                                                            >
                                                                <span
                                                                    className={cx(
                                                                        'group-hover:hidden text-gray-200'
                                                                    )}
                                                                >
                                                                    {user.firstName[0]}
                                                                    {user.lastName[0]}
                                                                </span>
                                                                <span className="hidden group-hover:inline text-gray-400 text-xl">
                                                                    X
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li className="flex items-center">
                                                    {selectedUsers.length > 0 &&
                                                        `+${selectedUsers.length}`}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button type="submit" onClick={appForm.handleSubmit(handleCreateGroup)}>
                                Create
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}
