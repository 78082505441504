import apiRequest from '@/lib/apiClient'
export type UserClaims = {
    email: string
    givenName: string
    surname: string
    subject: string
    groups: string[]
    activeOrganizationClaim: {
        id: string
        name: string
        role: string[]
        attributes: Record<string, string>
    }
}

export const getUserProfile = async (): Promise<UserClaims> => {
    return apiRequest('/Profile')
}
