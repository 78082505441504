import apiRequest from '@/lib/apiClient'
import { z } from 'zod'

export interface GetConversationResponse {
    userId: string
    conversationId: string
    messages: UserMessage[]
    title: string
    websocketUrl: string
}

export interface UserMessage {
    id: string
    message: string
    createdAt: string
    updatedAt: string
    role: string
    images: string[]
}

export interface PostConversationRequest {
    userId: string
    title: string
}

export interface PostConversationResponse {
    userId: string
    conversationId: string
    websocketUrl: string
}

export const WebSocketMessage = z.object({
    Token: z.number().nullable(),
    Duration: z.number().nullable(),
    Content: z.string().nullable(),
    Index: z.number(),
})
export type WebSocketMessageType = z.infer<typeof WebSocketMessage>

export const Message = z.object({
    id: z.string().nullable(),
    role: z.string().nullable(),
    images: z.array(z.string()).nullish(),
    createdAt: z.string().nullable(),
    updatedAt: z.string().nullable(),
    message: z.string().nullable(),
    requestCompleted: z.boolean().optional(),
    duration: z.number().optional(),
    totalTokens: z.number().optional(),
})

export type MessageType = z.infer<typeof Message>

export const Conversation = z.object({
    conversationId: z.string(),
    messages: z.array(Message),
    title: z.string().nullable(),
    websocketUrl: z.string(),
})

export type ConversationType = z.infer<typeof Conversation>

export const getConversationById = (id: string): Promise<GetConversationResponse> => {
    return apiRequest(`/api/v1/Conversations/${id}`)
}

export const createConversation = async (
    conversation: PostConversationRequest
): Promise<PostConversationResponse> => {
    return apiRequest(`/api/v1/Conversations`, {
        method: 'POST',
        body: conversation,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': import.meta.env.RENDERER_VITE_API_KEY,
        },
    })
}

export const UpdateChatName = async ({
    name,
    conversationId,
}: {
    name: string
    conversationId: string
}): Promise<void> => {
    return apiRequest(`/api/v1/Conversations/${conversationId}`, {
        method: 'PUT',
        body: { title: name },
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': import.meta.env.RENDERER_VITE_API_KEY,
        },
    })
}
