import { TabsTrigger } from '@radix-ui/react-tabs'

export type TabTriggerProps = {
    value: string
    icon: JSX.Element
    label: string
}

export const TabTrigger: React.FC<TabTriggerProps> = ({ value, icon, label }) => (
    <TabsTrigger
        value={value}
        className="bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md hover:cursor-pointer last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary"
    >
        {icon}
        {label}
    </TabsTrigger>
)
