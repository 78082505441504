import apiRequest from '@/lib/apiClient'
export interface ProxyReportData {
    createdAt: string
    id: string
    apiKeyName: string
    streamed: boolean
    duration: string
    requestModel: string
    responseModel: string
    providerType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    clientIpAddress?: string
    userAgent?: string
}
export interface ProxyReportConversation {
    id: string
    conversationId: string
    message: string
    images?: string[]
    role: 'system' | 'user' | 'assistant'
    createdAt: string
    updatedAt: string
}

export const getProxyReport = async (projectId: string): Promise<ProxyReportData[]> => {
    return apiRequest(`/GatewayUsage/project/${projectId}`)
}

export const getTenantProxyReport = async (): Promise<ProxyReportData[]> => {
    return apiRequest(`/GatewayUsage/tenant`)
}

export const getProxyReportConversation = async (
    executionId: string
): Promise<ProxyReportConversation[]> => {
    return apiRequest(`/GatewayUsage/details/${executionId}`)
}
