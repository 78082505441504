import { Connector } from '@/api/connectors.tsx'
import { ConnectorCard } from '@/components/featuredConnectors/ConnectorCard'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { useRouter } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import MegaSearch from '@/components/megaSearch/MegaSearch.tsx'

interface ConnectorsLibraryProps {
    connectors: Connector[]
}

export function ConnectorsLibrary({ connectors }: ConnectorsLibraryProps) {
    const router = useRouter()
    const [search, setSearch] = useState('')
    const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>(connectors)
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    const [selectedConnectorId, setSelectedConnectorId] = useState('')

    useEffect(() => {
        if (search !== '') {
            setFilteredConnectors(() =>
                connectors.filter(
                    (connector) =>
                        connector.name.toLowerCase().includes(search.toLowerCase()) ||
                        connector.classification.toLowerCase().includes(search.toLowerCase())
                )
            )
        } else {
            setFilteredConnectors(connectors)
        }
    }, [search, connectors])

    const onCardClick = (connectorId: string) => {
        setSelectedConnectorId(connectorId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        router.navigate({
            to: '/$projectId/datasets/new',
            params: {
                projectId,
            },
            search: {
                connectorId: selectedConnectorId,
            },
        })
    }

    return (
        <div className={'gap-6 mt-2 mx-1'}>
            <div className={'my-6 px-[15%]'}>
                <MegaSearch
                    onSearch={setSearch}
                    placeholder={'Search by Connector Name or Category'}
                />
            </div>
            <div
                className={
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-8'
                }
            >
                {filteredConnectors
                    .sort((c) =>
                        c.id === 'AzureBlob' || c.id === 'CloudKit' || c.id === 'FileUpload'
                            ? -1
                            : 1
                    ) // Sort connectors so that the ones that can be used for datasets are at the top
                    .map((connector) => (
                        <ConnectorCard
                            key={connector.id}
                            connector={connector}
                            onCardClick={() => onCardClick(connector.id)}
                            isDisabled={
                                !(
                                    connector.id === 'AzureBlob' ||
                                    connector.id === 'CloudKit' ||
                                    connector.id === 'FileUpload'
                                )
                            }
                        />
                    ))}
            </div>
            <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                <DialogContent>
                    <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
